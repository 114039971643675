import React from "react";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
export default function Licence() {
  return (
    <div className="wrapper">
      <div class="wrapper-sec">
        <div class="wrapper-title">Verify your Licence</div>
        <div class="wrapper=body">
          <Form>
            <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
              <Form.Label>DL number</Form.Label>
              <Form.Control type="number" placeholder="name@example.com" />
            </Form.Group>
            <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
              <Form.Label>Date of birth</Form.Label>
              <Form.Control type="date" placeholder="name@example.com" />
            </Form.Group>
          </Form>
          <Button variant="primary" style={{width:"100%"}}>Verify now</Button>
        </div>
      </div>
    </div>
  );
}
