import React, { useLayoutEffect, useState } from "react";
import Logo from "../components/Logo";
import { NavLink, Outlet, useSearchParams } from "react-router-dom";
import { Dropdown, Spinner } from "react-bootstrap";
import { PersonCircle } from "@styled-icons/bootstrap/PersonCircle";
import axios from "axios";
import { toast } from "react-toastify";
import Loader from "../components/Loader";
import NoMatch from "../components/NoMatch";
export let clientSecret = "";
export let token = "";
export default function Layout() {
  const [dropdownShow, setDropdownShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [queryParameters] = useSearchParams();
  const [validateToken, setValidateToken] = useState(false)
useLayoutEffect(() => {
  setLoading(true)
  const params = queryParameters.get('token');
  axios
    .post(`/api/v1/external/gateway/verify/token/`, {
      token: params,
    })
    .then(function (response) {
      token = params;
      clientSecret = response?.data?.data?.client_secret;
      setValidateToken(response?.status);
    })
    .catch(function (error) {
      setValidateToken(error?.response?.data?.status);
    })
    .finally(() => {
      setLoading(false);
    });
}, []);
  return (
    <>
      {/* <div className="sidebar">
        <div className="logo">
           <div style={{width:'200px', height:'100%'}}>
           <Logo fill="#fff" />
           </div>
       
        </div>
        <ul className="menu-section">
          <li class="list">
            <NavLink to="/">
              <span>Aadhaar</span>
            </NavLink>
          </li>
          <li class="list">
            <NavLink to="/licence">
              <span>Licence</span>
            </NavLink>
          </li>
          <li class="list">
            <NavLink to="/pan">
              <span>Pan</span>
            </NavLink>
          </li>
        </ul>
      </div> */}
      <div className="content-wrapper">
        <div className="top-nav">
          <div style={{ width: "150px", height: "100%" }}>
            <Logo fill="#000" />
          </div>
          {/* <div>
            <PersonCircle size="32" />
            <span>Vikash Kumar</span>
          </div> */}
        </div>
        <div className="content-body">
          {loading && !validateToken ? (
             <div className="loader">
                 <Spinner animation="border" variant="light" />
             </div>
          ) : validateToken ? (
            <Outlet />
          ) : (
           <NoMatch />
          )}
          
        </div>
      </div>
    </>
  );
}
