import React from "react";
import Layout from "./src/container/Layout";
import * as bootstrap from "@styled-icons/bootstrap";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

import Licence from "./src/container/Licence";
import Pan from "./src/container/Pan";
import AAdharVerification from "./src/container/AAdharVerification";



const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <AAdharVerification />,
      },
      {
        path: "licence",
        element: <Licence />,
      },
      {
        path: "pan",
        element: <Pan />,
      },
    ],
  },
]);

const App = () => {
  return <RouterProvider router={router} />;
};

export default App;
