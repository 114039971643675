import React from "react";

export default function Logo({fill}) {
  return (


<svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1050 300" width="200" height="80" enableBackground="new 0 0 1050 300">
	<defs>
		<clipPath clipPathUnits="userSpaceOnUse" id="cp1">
			<path d="m664.59 4.22h173.58v52.79h-173.58z"/>
		</clipPath>
		<clipPath clipPathUnits="userSpaceOnUse" id="cp2">
			<path d="m664.59 90.48h147.93v51.06h-147.93z"/>
		</clipPath>
		<clipPath clipPathUnits="userSpaceOnUse" id="cp3">
			<path d="m664.59 175.01h173.58v52.79h-173.58z"/>
		</clipPath>
		<clipPath clipPathUnits="userSpaceOnUse" id="cp4">
			<path d="m871.88 4.22h173.58v52.79h-173.58z"/>
		</clipPath>
		<clipPath clipPathUnits="userSpaceOnUse" id="cp5">
			<path d="m871.88 90.48h147.93v51.06h-147.93z"/>
		</clipPath>
		<clipPath clipPathUnits="userSpaceOnUse" id="cp6">
			<path d="m871.88 175.01h173.58v52.79h-173.58z"/>
		</clipPath>
	</defs>
	<style>
		{` 
		.s1 { fill: #2fbf71 } `}
	</style>
	<g id="Layer">
		<path id="Layer" style={{fill:fill}} d="m422 12.8l-71.6 116c-0.8 1.3-2.8 1.3-3.6-0.1l-30.2-52.6c-0.3-0.7-0.4-1.5 0-2.1l36-63.4q1.5-2.7 4.8-4.5 3.2-1.9 6.2-1.9h55.1q3 0 4.4 2.2 1.4 2.1-1.1 6.4z"/>
		<path id="Layer" style={{fill:fill}} d="m63.8 176c-1.5 1.9-2.4 4.2-2.4 6.6v37.9q0 3.4-2.6 5.6c-1.3 1.1-3 1.7-4.7 1.7h-45.9q-3.4 0-5.3-2.1-2-2.2-2-5.2v-209.3q0-3 2-5.1 2-2.2 5.3-2.2h45.9c1.7 0 3.4 0.6 4.7 1.7q2.6 2.3 2.6 5.7v70.3q0 3.1 1.3 3.5 1.2 0.5 3-2l55.1-73.1q1.8-2.4 5.2-4.1 3.3-1.7 6.7-1.7h58.4q3.4 0 4.3 2.9 0.9 2.9-0.9 5.4z"/>
		<path id="Layer" style={{fill:fill}} d="m340.2 132.2c0.9 1.5 1.4 3.3 1.4 5.1v83.2q0 3.4-2.6 5.6c-1.3 1.1-3 1.7-4.7 1.7h-46c-1.7 0-3.3-0.6-4.6-1.7q-2.7-2.2-2.7-5.6v-69.8q0-3-1-7.1-1.1-4.2-2.6-6.6-18.4-31.5-37.5-61.8-19.1-30.3-38.1-62.4-1.6-2.8-0.5-5.9c0.6-1.6 2.2-2.7 3.9-2.7h54.4q3.1 0 6.3 1.9 3.2 1.8 4.7 4.6z"/>
		<path id="Layer" style={{fill:fill}} d="m642 168.5q-8.9 14.3-19.6 26-10.7 11.7-24 19.9-13.3 8.3-29.5 12.8-16.3 4.6-35.8 4.6-24.5 0-45.9-9.2-21.4-9.2-37.5-24.8-16.1-15.6-25.2-36.7-9.2-21.1-9.2-44.9 0-23.9 9.2-45 9.2-21.1 25.2-36.7 16.1-15.6 37.5-24.8 21.4-9.2 45.9-9.2 20.1 0 36.4 4.6 16.2 4.6 29.3 12.9 13.2 8.3 23.7 20 10.6 11.8 19.4 25.9 1.6 2.4 0.7 5.2-1 2.7-3.7 3.9l-43.4 17.2q-3.1 1.2-6.2 0-3-1.3-4.6-3.7-8.8-14.1-21.4-21.6-12.5-7.4-30.2-7.5-11.7 0.1-21.9 5-10.3 4.9-17.9 13-7.7 8.1-11.9 18.8-4.3 10.8-4.3 22.1 0 11.7 4.3 22.3 4.2 10.5 11.9 18.5 7.6 8 17.9 12.9 10.2 4.9 21.9 4.9 16.8 0 30.4-8.1 13.6-8.1 21.5-21 1.6-2.4 4.5-3.6 2.9-1.2 5.9 0l43.5 17.1q2.7 1.2 3.6 3.8 1 2.6-0.5 5.4z"/>
		<g id="Layer">
			<g id="Layer">
				<g id="Clip-Path" clipPath="url(#cp1)">
					<g id="Layer">
						<path id="Layer" className="s1" d="m662.8 108.6c1.4 0 2.5-1.1 2.5-2.4 0-1.4-1.1-2.5-2.5-2.5h-27.7q0 1.1 0 2.1 0 1.4-0.1 2.8z"/>
						<path id="Layer" className="s1" d="m684.1 58.1c-0.7 1.1-0.3 2.6 0.9 3.3q0.6 0.4 1.2 0.4c0.9 0 1.7-0.5 2.2-1.2 13-21.9 34.7-37.3 59.6-42.2 11.5-2.2 19.8-12.2 19.8-23.7v-52.2q-2.5-0.4-4.9-0.8v53c0 9.1-6.7 17.1-15.8 18.9-26.3 5.2-49.2 21.4-63 44.5z"/>
						<path id="Layer" className="s1" d="m680.9 69.6c-1.2-0.4-2.6 0.2-3.2 1.4-4.5 11.2-6.7 23-6.7 35.2 0 21.6 7.1 41.9 20.5 58.8 0.5 0.6 1.2 0.9 1.9 0.9 0.6 0 1.1-0.2 1.6-0.5 1-0.9 1.2-2.4 0.3-3.5-12.7-15.9-19.4-35.2-19.4-55.7 0-11.5 2.1-22.8 6.4-33.4 0.5-1.2-0.1-2.7-1.4-3.2z"/>
						<path id="Layer" className="s1" d="m749.8 194.3c-16-2.8-31-10-43.3-20.7-1-0.9-2.6-0.8-3.5 0.2-0.9 1-0.7 2.6 0.3 3.5 12.9 11.3 28.7 18.8 45.6 21.8 8.1 1.4 14 8.5 14 16.8v54q2.4-0.3 4.9-0.8v-53.3c0-10.6-7.6-19.7-18-21.5z"/>
						<path id="Layer" className="s1" d="m760.9 22.5c-1.1 0.7-1.6 2.2-0.9 3.4 0.5 0.8 1.3 1.2 2.1 1.2q0.7 0 1.3-0.3c9.1-5.2 15.4-14.6 16.9-25.1 0.2-1.3-0.8-2.6-2.1-2.8-1.4-0.2-2.6 0.8-2.8 2.1-1.2 9-6.7 17.1-14.5 21.5z"/>
						<path id="Layer" className="s1" d="m778.2-9.4c1.3 0 2.4-1.1 2.4-2.4v-42.1q-2.4-0.8-4.9-1.6v43.7c0 1.3 1.1 2.4 2.5 2.4z"/>
						<path id="Layer" className="s1" d="m752 181.6c-3.2-0.6-6.5-1.3-9.6-2.3-1.3-0.4-2.6 0.4-3 1.6-0.4 1.3 0.3 2.7 1.6 3.1 3.3 1 6.8 1.8 10.2 2.4 14.2 2.4 24.5 14.6 24.5 29.1v51.6q2.5-0.7 4.9-1.6v-50c0-16.9-12-31.2-28.6-33.9z"/>
						<path id="Layer" className="s1" d="m719.8 173.7c2.6 1.8 5.3 3.4 8 4.8q0.5 0.3 1.1 0.3c0.9 0 1.7-0.5 2.2-1.3 0.6-1.2 0.1-2.7-1.1-3.3-2.5-1.3-5-2.9-7.5-4.5-21.1-14.3-33.8-38.1-33.8-63.5 0-27.7 15.1-53.3 39.4-66.9 6.3-3.6 12.6-6 19.3-7.6 1.3-0.3 2.1-1.6 1.8-2.9-0.3-1.4-1.6-2.2-2.9-1.9-7.1 1.7-13.9 4.3-20.6 8.1-25.8 14.4-41.9 41.7-41.9 71.2 0 27.1 13.5 52.3 36 67.5z"/>
						<path id="Layer" className="s1" d="m754 169.5c-8.8-1.5-16.9-4.8-24.6-10-17.8-12-28.4-32-28.4-53.3 0-23.3 12.6-44.8 33-56.2 6.6-3.7 13.2-6 20.2-7.2 22.4-3.6 38.7-23 38.7-45.9v-45.4q-2.5-1.3-4.9-2.4v47.8c0 20.5-14.6 37.8-34.6 41.1-7.6 1.2-14.7 3.8-21.8 7.7-21.9 12.3-35.5 35.4-35.5 60.5 0 23 11.4 44.4 30.5 57.4 8.4 5.6 17.1 9.1 26.6 10.7 20.2 3.3 34.8 20.7 34.8 41.2v47.1q2.4-1.2 4.9-2.4v-44.7c0-22.9-16.4-42.3-38.9-46z"/>
						<path id="Layer" className="s1" d="m714.5 86.3c-1.3-0.5-2.7 0.2-3.1 1.5-2 5.9-3.1 12.1-3.1 18.4 0 18.9 9.4 36.6 25.2 47.2 6.9 4.6 14 7.5 21.8 8.8 12 2 23.2 8.1 31.3 17.2 0.4 0.6 1.1 0.8 1.8 0.8 0.6 0 1.2-0.2 1.6-0.6 1-0.9 1.1-2.4 0.2-3.4-8.8-10-21-16.6-34.1-18.8-7.1-1.2-13.6-3.8-19.8-8-14.4-9.8-23-25.9-23-43.2 0-5.8 0.9-11.4 2.8-16.8 0.4-1.3-0.3-2.7-1.6-3.1z"/>
						<path id="Layer" className="s1" d="m796.8 190.1c-1.3 0.5-1.8 2-1.3 3.2 3.2 7 4.7 14.5 4.7 22.2v40.4q2.5-1.5 4.9-3.2v-37.2c0-8.4-1.7-16.6-5.1-24.2-0.5-1.2-2-1.8-3.2-1.2z"/>
						<path id="Layer" className="s1" d="m795.4 24.9c1.2 0.6 2.7 0.1 3.3-1.1 4.2-8.2 6.4-17.6 6.4-26.9v-37.9q-2.4-1.7-4.9-3.3v41.2c0 8.6-2 17.1-5.9 24.7-0.6 1.2-0.1 2.7 1.1 3.3z"/>
						<path id="Layer" className="s1" d="m740 60.7c5.3-3 10.6-4.9 16.2-5.8 14.5-2.4 27.5-10.1 36.5-21.7 0.9-1 0.7-2.6-0.4-3.4-1.1-0.8-2.6-0.7-3.4 0.4-8.3 10.6-20.2 17.7-33.5 19.9-6.2 1-12 3.1-17.8 6.3-8 4.5-14.8 10.9-19.9 18.5-0.7 1.1-0.4 2.6 0.7 3.4q0.7 0.4 1.4 0.4c0.8 0 1.6-0.4 2-1.1 4.6-7 10.9-12.8 18.2-16.9z"/>
						<path id="Layer" className="s1" d="m758.1 145.3c-5.3-0.9-10.2-2.9-15-6.1-11-7.4-17.6-19.8-17.6-33 0-14.4 7.9-27.7 20.5-34.8 4-2.3 8-3.7 12.2-4.4 34.3-5.6 59.2-35.1 59.2-70.1v-28q-2.4-2.3-4.9-4.3v32.3c0 32.6-23.2 60.1-55.1 65.3-4.8 0.8-9.3 2.4-13.8 4.9-14.2 7.9-23 22.9-23 39.1 0 14.8 7.4 28.7 19.8 37.1 5.3 3.6 10.8 5.9 16.8 6.9 32 5.3 55.3 32.8 55.3 65.3v31.6q2.5-2.1 4.9-4.3v-27.3c0-35-25-64.5-59.3-70.2z"/>
						<path id="Layer" className="s1" d="m796.7 57.2c-1 0.9-1.2 2.4-0.3 3.5 0.9 1 2.4 1.1 3.5 0.3 18.9-15.9 29.7-39.2 29.7-64.2v-14.9q-2.4-2.9-4.9-5.6v20.5c0 23.5-10.2 45.5-28 60.4z"/>
						<path id="Layer" className="s1" d="m760.1 133.2c-3.6-0.6-6.8-1.9-10.1-4.1-7.7-5.2-12.3-13.8-12.3-22.9 0-10 5.5-19.2 14.3-24.1 2.7-1.6 5.4-2.5 8.1-3 9.5-1.5 18.4-4.6 26.7-9.2 1.2-0.6 1.6-2.1 1-3.3-0.7-1.2-2.2-1.6-3.4-1-7.8 4.3-16.2 7.2-25.1 8.7-3.3 0.5-6.4 1.7-9.7 3.5-10.3 5.8-16.8 16.7-16.8 28.4 0 10.8 5.4 20.8 14.4 26.9 3.9 2.7 7.8 4.2 12.1 5 37.9 6.3 65.4 38.8 65.4 77.4v19.9q2.5-2.7 4.9-5.6v-14.3c0-41-29.2-75.6-69.5-82.3z"/>
						<path id="Layer" className="s1" d="m762.1 121.1c-1.9-0.3-3.4-0.9-5.3-2.2-4.2-2.9-6.8-7.7-6.8-12.7 0-5.6 3-10.7 8-13.5 1.5-0.8 2.8-1.3 4.1-1.5 45.5-7.5 78.7-46.1 79.7-92.3q-2.3-3.8-4.8-7.3v5.2c0 44.7-31.8 82.4-75.7 89.5-1.9 0.3-3.7 1-5.7 2.2-6.5 3.6-10.5 10.4-10.5 17.7 0 6.7 3.4 12.9 9 16.8 2.5 1.6 4.7 2.5 7.2 2.9 43.9 7.3 75.7 45 75.7 89.6v4.5q2.5-3.5 4.8-7.3c-1.3-45.7-34.4-84-79.7-91.6z"/>
						<path id="Layer" className="s1" d="m763.7 98.4c-0.4 0-0.9 0.1-2.1 0.8-2.6 1.4-4.3 4.1-4.3 7 0 2.6 1.4 5.1 3.7 6.6 1.1 0.8 1.6 1 2.3 1.1 24.8 4.1 47.1 17 62.9 36.4 0.8 1 2.4 1.2 3.4 0.3 1.1-0.8 1.2-2.4 0.4-3.4-16.6-20.3-39.9-33.9-65.8-38.1q-0.1-0.1-0.5-0.3c-0.9-0.7-1.4-1.6-1.4-2.6 0-1.1 0.6-2.2 1.7-2.8 0.2-0.1 0.3-0.1 0.2-0.1 44.9-7.4 79.1-41.6 87.7-84.9q-1.7-4-3.7-7.8c-5.8 44.5-39.7 80.4-84.5 87.8z"/>
						<path id="Layer" className="s1" d="m835.7 159.4c-1.1 0.7-1.5 2.2-0.8 3.4 6.9 11.7 11.4 24.7 13.3 38.3q1.9-3.8 3.6-7.7c-2.4-11.7-6.7-22.9-12.7-33.1-0.7-1.2-2.2-1.6-3.4-0.9z"/>
						<path id="Layer" className="s1" d="m789.3 103.9c-1.3 0.5-1.9 1.9-1.4 3.2 0.5 1.3 1.9 1.9 3.2 1.4 32.5-12.9 56.7-38.8 68.1-70.4q-1.2-4-2.6-7.8c-9.8 33.2-34.1 60.4-67.3 73.6z"/>
						<path id="Layer" className="s1" d="m809.1 112.9c-1.2-0.7-2.7-0.3-3.4 0.8-0.7 1.2-0.3 2.7 0.8 3.4 24.5 15 41.9 37.9 50 64.6q1.3-3.8 2.6-7.8c-9.1-25.1-26.4-46.6-50-61z"/>
						<path id="Layer" className="s1" d="m849.8 75.9c1.1 0.8 2.6 0.6 3.4-0.5q6.2-8.5 10.9-17.7-0.8-4-1.7-7.9c-3.6 7.9-7.9 15.6-13.2 22.7-0.8 1.1-0.5 2.6 0.6 3.4z"/>
						<path id="Layer" className="s1" d="m821.3 106.2c9.6-6.5 18.1-14.2 25.6-22.9 0.9-1 0.8-2.6-0.2-3.4-1.1-0.9-2.6-0.8-3.5 0.2-8 9.3-17.3 17.4-27.6 24-0.7 0.5-1.1 1.3-1.1 2.1 0 0.8 0.4 1.6 1.1 2.1 20.8 13.3 36.8 32.2 46.7 54q0.9-3.8 1.7-7.7c-9.8-19.2-24.3-35.9-42.7-48.4z"/>
						<path id="Layer" className="s1" d="m841.4 106.2c9.9-8.6 18.5-18.4 25.6-29.2q-0.4-3.9-0.9-7.7c-7.9 13.2-18 25-30.1 35-0.5 0.5-0.8 1.2-0.8 1.9 0 0.7 0.3 1.4 0.8 1.9 12 10 22.1 21.8 30 34.8q0.5-3.7 0.9-7.6c-7-10.7-15.6-20.5-25.5-29.1z"/>
						<path id="Layer" className="s1" d="m868.2 96q-0.1-3.8-0.3-7.5-6.3 8.4-13.9 16c-0.4 0.4-0.7 1.1-0.7 1.7 0 0.7 0.3 1.3 0.7 1.7 5.1 5.1 9.7 10.4 13.9 16q0.2-3.7 0.3-7.5c-2.8-3.5-5.8-6.9-9-10.2 3.2-3.3 6.2-6.7 9-10.2z"/>
						<path id="Layer" className="s1" d="m740.3 205.4c-36.2-9.1-64.5-37.2-73.9-73.3-2.4-9.1-10.7-15.5-20.1-15.5h-11.1q0.1 2.5 0.2 4.9h10.9c7.2 0 13.5 4.9 15.3 11.8 9.9 37.9 39.6 67.3 77.5 76.8 6.4 1.7 10.9 7.5 10.9 14.2v46.3q0.7 0 1.5 0 0.1 0 0.2 0 0.1 0 0.2 0c1 0 2 0 3-0.1v-46.2c0-9-6-16.7-14.6-18.9z"/>
						<path id="Layer" className="s1" d="m737.3 217.3c-40.5-10.3-72.2-41.7-82.8-82.1-1-3.7-4.3-6.3-8.2-6.3h-10.5c0.2 1.6 0.3 3.2 0.5 4.8h10c1.6 0 3.1 1.2 3.5 2.8 11 42.1 44.1 74.9 86.3 85.5 1 0.3 1.6 1.2 1.6 2.3v1.6c0 1.3 1.1 2.4 2.5 2.4 1.3 0 2.5-1.1 2.5-2.4v-1.6c-0.1-3.3-2.3-6.2-5.4-7z"/>
						<path id="Layer" className="s1" d="m740.2 235.2c-1.4 0-2.5 1.1-2.5 2.5v31.8q2.5 0.4 4.9 0.6v-32.4c0-1.4-1.1-2.5-2.4-2.5z"/>
						<path id="Layer" className="s1" d="m670.9 194.5c1-1 1-2.5 0.1-3.5-12.3-13.8-21.7-30.4-27.1-48.1-0.3-1.1-1.2-1.8-2.3-1.8h-4.4c0.2 1.7 0.4 3.3 0.6 4.9h2c5.6 17.8 15.1 34.4 27.6 48.3 0.9 1 2.4 1.1 3.5 0.2z"/>
						<path id="Layer" className="s1" d="m728.6 227.6c-17.5-5.3-33.3-14.1-47-26.2-1.1-0.9-2.6-0.8-3.5 0.2-0.9 1-0.8 2.6 0.2 3.5 13.8 12.1 29.7 21.1 47.2 26.6v34.8q2.4 0.8 4.9 1.4v-38c0-1-0.7-2-1.8-2.3z"/>
						<path id="Layer" className="s1" d="m716.5 236.4c-30.4-11.4-55.6-32.9-71.6-60.7q3.4 10.6 7.7 20.1c15.8 19.8 36.6 35.3 60.6 44.6v21.1q2.5 1.2 4.9 2.2v-25c0-1-0.6-1.9-1.6-2.3z"/>
						<path id="Layer" className="s1" d="m704.4 244.7c-13.4-5.9-25.8-13.6-36.8-22.9 4.1 5.7 8.4 10.9 13 15.6 6.4 4.2 13.2 7.9 20.4 11.2v5.9q2.4 1.6 4.9 3.1v-10.6c0-1-0.6-1.9-1.5-2.3z"/>
						<path id="Layer" className="s1" d="m666.4 80.3c9.4-36.1 37.7-64.2 73.9-73.3 8.6-2.2 14.6-10 14.6-18.9v-47c-1 0-2-0.1-3-0.1q-0.1 0-0.2 0-0.1 0-0.2 0-0.8 0.1-1.5 0.1v47c0 6.7-4.5 12.5-10.9 14.1-37.9 9.6-67.6 39-77.5 76.8-1.8 7-8.1 11.9-15.3 11.9h-11q-0.1 2.4-0.1 4.9h11.1c9.4 0 17.7-6.4 20.1-15.5z"/>
						<path id="Layer" className="s1" d="m678.6 27.6c0.5 0.5 1.1 0.7 1.7 0.7 0.6 0 1.3-0.3 1.8-0.8 14.9-15.8 34-27 55.2-32.4 3.1-0.8 5.3-3.7 5.3-7v-13c0-1.3-1.1-2.4-2.4-2.4-1.4 0-2.5 1.1-2.5 2.4v13c0 1.1-0.7 2-1.6 2.3-22.1 5.5-42 17.2-57.6 33.7-0.9 1-0.9 2.6 0.1 3.5z"/>
						<path id="Layer" className="s1" d="m654.5 77.2c3.7-14 9.8-27 18.4-38.7 0.8-1.1 0.5-2.6-0.6-3.4-1.1-0.8-2.6-0.5-3.4 0.5-8.9 12.2-15.3 25.7-19.1 40.3-0.5 1.6-1.9 2.7-3.5 2.7h-10.1c-0.1 1.6-0.3 3.3-0.4 4.9h10.5c3.9 0 7.2-2.6 8.2-6.3z"/>
						<path id="Layer" className="s1" d="m740.2-32.3c1.3 0 2.4-1.1 2.4-2.4v-23.8q-2.4 0.3-4.9 0.7v23.1c0 1.3 1.1 2.4 2.5 2.4z"/>
						<path id="Layer" className="s1" d="m643.9 69.5c12.3-40.7 44-72.4 84.8-84.7 1-0.3 1.7-1.3 1.7-2.4v-38.7q-2.5 0.7-4.9 1.5v35.4c-40.9 13-72.8 44.8-85.7 85.8h-2c-0.3 1.6-0.5 3.2-0.7 4.9h4.5c1.1 0 2-0.7 2.3-1.8z"/>
						<path id="Layer" className="s1" d="m716.5-24.1c1-0.3 1.6-1.2 1.6-2.3v-25.7q-2.4 1.1-4.9 2.2v21.9c-24.4 9.4-45.5 25.3-61.3 45.6q-4.2 9.4-7.5 19.9c16-28.2 41.4-50.1 72.1-61.6z"/>
						<path id="Layer" className="s1" d="m705.9-34.6v-11.3q-2.5 1.4-4.9 3v6.7c-7.8 3.5-15.2 7.6-22.1 12.3q-6.7 7.1-12.6 15.6c11.4-9.8 24.1-17.9 38.1-24.1 0.9-0.3 1.5-1.2 1.5-2.2z"/>
					</g>
				</g>
			</g>
			<g id="Layer">
				<g id="Clip-Path" clipPath="url(#cp2)">
					<g id="Layer">
						<path id="Layer" className="s1" d="m662.8 114c1.4 0 2.5-1.1 2.5-2.5 0-1.3-1.1-2.4-2.5-2.4h-27.7q0 1 0 2.1 0 1.4-0.1 2.8z"/>
						<path id="Layer" className="s1" d="m684.1 63.4c-0.7 1.2-0.3 2.7 0.9 3.4q0.6 0.3 1.2 0.3c0.9 0 1.7-0.4 2.2-1.2 13-21.9 34.7-37.3 59.6-42.2 11.5-2.2 19.8-12.2 19.8-23.7v-52.1q-2.5-0.5-4.9-0.8v52.9c0 9.2-6.7 17.1-15.8 18.9-26.3 5.2-49.2 21.4-63 44.5z"/>
						<path id="Layer" className="s1" d="m680.9 75c-1.2-0.5-2.6 0.1-3.2 1.3-4.5 11.2-6.7 23.1-6.7 35.2 0 21.6 7.1 41.9 20.5 58.8 0.5 0.6 1.2 0.9 1.9 0.9 0.6 0 1.1-0.1 1.6-0.5 1-0.8 1.2-2.4 0.3-3.5-12.7-15.9-19.4-35.2-19.4-55.7 0-11.5 2.1-22.7 6.4-33.3 0.5-1.3-0.1-2.7-1.4-3.2z"/>
						<path id="Layer" className="s1" d="m749.8 199.6c-16-2.8-31-10-43.3-20.7-1-0.9-2.6-0.8-3.5 0.2-0.9 1-0.7 2.6 0.3 3.5 12.9 11.3 28.7 18.9 45.6 21.8 8.1 1.4 14 8.5 14 16.8v54q2.4-0.3 4.9-0.8v-53.2c0-10.7-7.6-19.8-18-21.6z"/>
						<path id="Layer" className="s1" d="m760.9 27.9c-1.1 0.6-1.6 2.1-0.9 3.3 0.5 0.8 1.3 1.2 2.1 1.2q0.7 0 1.3-0.3c9.1-5.2 15.4-14.6 16.9-25.1 0.2-1.3-0.8-2.6-2.1-2.7-1.4-0.3-2.6 0.7-2.8 2-1.2 9.1-6.7 17.1-14.5 21.6z"/>
						<path id="Layer" className="s1" d="m778.2-4.1c1.3 0 2.4-1.1 2.4-2.4v-42q-2.4-0.9-4.9-1.6v43.6c0 1.3 1.1 2.4 2.5 2.4z"/>
						<path id="Layer" className="s1" d="m752 186.9c-3.2-0.5-6.5-1.3-9.6-2.3-1.3-0.3-2.6 0.4-3 1.7-0.4 1.3 0.3 2.6 1.6 3 3.3 1 6.8 1.8 10.2 2.4 14.2 2.4 24.5 14.6 24.5 29.1v51.7q2.5-0.8 4.9-1.6v-50.1c0-16.9-12-31.2-28.6-33.9z"/>
						<path id="Layer" className="s1" d="m719.8 179c2.6 1.8 5.3 3.4 8 4.8q0.5 0.3 1.1 0.3c0.9 0 1.7-0.5 2.2-1.3 0.6-1.2 0.1-2.7-1.1-3.3-2.5-1.3-5-2.8-7.5-4.5-21.1-14.3-33.8-38-33.8-63.5 0-27.7 15.1-53.3 39.4-66.9 6.3-3.5 12.6-6 19.3-7.6 1.3-0.3 2.1-1.6 1.8-2.9-0.3-1.3-1.6-2.2-2.9-1.8-7.1 1.6-13.9 4.3-20.6 8-25.8 14.5-41.9 41.7-41.9 71.2 0 27.1 13.5 52.3 36 67.5z"/>
						<path id="Layer" className="s1" d="m754 174.8c-8.8-1.5-16.9-4.7-24.6-10-17.8-12-28.4-31.9-28.4-53.3 0-23.3 12.6-44.8 33-56.2 6.6-3.7 13.2-6 20.2-7.1 22.4-3.7 38.7-23.1 38.7-46v-45.4q-2.5-1.3-4.9-2.4v47.8c0 20.5-14.6 37.8-34.6 41.1-7.6 1.3-14.7 3.8-21.8 7.7-21.9 12.3-35.5 35.5-35.5 60.5 0 23 11.4 44.5 30.5 57.4 8.4 5.6 17.1 9.1 26.6 10.7 20.2 3.4 34.8 20.7 34.8 41.2v47.1q2.4-1.1 4.9-2.4v-44.7c0-22.9-16.4-42.3-38.9-46z"/>
						<path id="Layer" className="s1" d="m714.5 91.6c-1.3-0.4-2.7 0.3-3.1 1.5-2 6-3.1 12.1-3.1 18.4 0 18.9 9.4 36.6 25.2 47.2 6.9 4.7 14 7.6 21.8 8.8 12 2 23.2 8.2 31.3 17.2 0.4 0.6 1.1 0.9 1.8 0.9 0.6 0 1.2-0.2 1.6-0.7 1-0.9 1.1-2.4 0.2-3.4-8.8-9.9-21-16.6-34.1-18.8-7.1-1.2-13.6-3.8-19.8-8-14.4-9.8-23-25.9-23-43.2 0-5.7 0.9-11.4 2.8-16.8 0.4-1.3-0.3-2.6-1.6-3.1z"/>
						<path id="Layer" className="s1" d="m796.8 195.4c-1.3 0.5-1.8 2-1.3 3.2 3.2 7 4.7 14.5 4.7 22.2v40.4q2.5-1.5 4.9-3.2v-37.2c0-8.4-1.7-16.6-5.1-24.2-0.5-1.2-2-1.8-3.2-1.2z"/>
						<path id="Layer" className="s1" d="m795.4 30.2c1.2 0.6 2.7 0.1 3.3-1.1 4.2-8.2 6.4-17.5 6.4-26.9v-37.9q-2.4-1.7-4.9-3.2v41.1c0 8.6-2 17.2-5.9 24.7-0.6 1.2-0.1 2.7 1.1 3.3z"/>
						<path id="Layer" className="s1" d="m740 66c5.3-2.9 10.6-4.8 16.2-5.8 14.5-2.3 27.5-10 36.5-21.6 0.9-1.1 0.7-2.7-0.4-3.5-1.1-0.8-2.6-0.6-3.4 0.4-8.3 10.7-20.2 17.7-33.5 19.9-6.2 1-12 3.1-17.8 6.3-8 4.5-14.8 10.9-19.9 18.5-0.7 1.1-0.4 2.7 0.7 3.4q0.7 0.4 1.4 0.4c0.8 0 1.6-0.4 2-1.1 4.6-7 10.9-12.8 18.2-16.9z"/>
						<path id="Layer" className="s1" d="m758.1 150.6c-5.3-0.8-10.2-2.8-15-6.1-11-7.4-17.6-19.8-17.6-33 0-14.4 7.9-27.7 20.5-34.8 4-2.3 8-3.7 12.2-4.4 34.3-5.6 59.2-35.1 59.2-70.1v-28q-2.4-2.2-4.9-4.3v32.3c0 32.6-23.2 60.1-55.1 65.3-4.8 0.8-9.3 2.4-13.8 4.9-14.2 8-23 22.9-23 39.1 0 14.9 7.4 28.7 19.8 37.1 5.3 3.6 10.8 5.9 16.8 6.9 32 5.3 55.3 32.8 55.3 65.3v31.6q2.5-2 4.9-4.3v-27.3c0-34.9-25-64.5-59.3-70.2z"/>
						<path id="Layer" className="s1" d="m796.7 62.5c-1 0.9-1.2 2.4-0.3 3.5 0.9 1 2.4 1.2 3.5 0.3 18.9-15.8 29.7-39.2 29.7-64.1v-15q-2.4-2.9-4.9-5.6v20.6c0 23.4-10.2 45.4-28 60.3z"/>
						<path id="Layer" className="s1" d="m760.1 138.5c-3.6-0.6-6.8-1.9-10.1-4.1-7.7-5.2-12.3-13.8-12.3-22.9 0-10 5.5-19.2 14.3-24.1 2.7-1.6 5.4-2.5 8.1-3 9.5-1.5 18.4-4.6 26.7-9.2 1.2-0.6 1.6-2.1 1-3.3-0.7-1.2-2.2-1.6-3.4-1-7.8 4.3-16.2 7.2-25.1 8.7-3.3 0.5-6.4 1.7-9.7 3.5-10.3 5.8-16.8 16.7-16.8 28.4 0 10.8 5.4 20.9 14.4 26.9 3.9 2.7 7.8 4.3 12.1 5 37.9 6.3 65.4 38.8 65.4 77.4v19.9q2.5-2.7 4.9-5.6v-14.3c0-41-29.2-75.6-69.5-82.3z"/>
						<path id="Layer" className="s1" d="m762.1 126.5c-1.9-0.3-3.4-1-5.3-2.3-4.2-2.9-6.8-7.6-6.8-12.7 0-5.5 3-10.7 8-13.4 1.5-0.9 2.8-1.4 4.1-1.6 45.5-7.4 78.7-46.1 79.7-92.2q-2.3-3.8-4.8-7.4v5.3c0 44.6-31.8 82.3-75.7 89.5-1.9 0.3-3.7 1-5.7 2.1-6.5 3.6-10.5 10.4-10.5 17.7 0 6.7 3.4 13 9 16.8 2.5 1.6 4.7 2.6 7.2 3 43.9 7.3 75.7 44.9 75.7 89.5v4.6q2.5-3.6 4.8-7.4c-1.3-45.7-34.4-84-79.7-91.5z"/>
						<path id="Layer" className="s1" d="m763.7 103.7c-0.4 0-0.9 0.1-2.1 0.8-2.6 1.4-4.3 4.1-4.3 7 0 2.6 1.4 5.1 3.7 6.6 1.1 0.8 1.6 1 2.3 1.1 24.8 4.1 47.1 17 62.9 36.4 0.8 1 2.4 1.2 3.4 0.3 1.1-0.8 1.2-2.4 0.4-3.4-16.6-20.3-39.9-33.8-65.8-38.1q-0.1-0.1-0.5-0.3c-0.9-0.6-1.4-1.6-1.4-2.6 0-1.1 0.6-2.2 1.7-2.7 0.2-0.2 0.3-0.2 0.2-0.2 44.9-7.4 79.1-41.6 87.7-84.8q-1.7-4-3.7-7.9c-5.8 44.5-39.7 80.5-84.5 87.8z"/>
						<path id="Layer" className="s1" d="m835.7 164.7c-1.1 0.7-1.5 2.2-0.8 3.4 6.9 11.7 11.4 24.8 13.3 38.3q1.9-3.8 3.6-7.7c-2.4-11.7-6.7-22.9-12.7-33.1-0.7-1.2-2.2-1.5-3.4-0.9z"/>
						<path id="Layer" className="s1" d="m789.3 109.2c-1.3 0.5-1.9 2-1.4 3.2 0.5 1.3 1.9 1.9 3.2 1.4 32.5-12.9 56.7-38.7 68.1-70.4q-1.2-3.9-2.6-7.8c-9.8 33.2-34.1 60.5-67.3 73.6z"/>
						<path id="Layer" className="s1" d="m809.1 118.2c-1.2-0.7-2.7-0.3-3.4 0.8-0.7 1.2-0.3 2.7 0.8 3.4 24.5 15 41.9 37.9 50 64.6q1.3-3.8 2.6-7.8c-9.1-25-26.4-46.6-50-61z"/>
						<path id="Layer" className="s1" d="m849.8 81.3c1.1 0.8 2.6 0.5 3.4-0.6q6.2-8.5 10.9-17.7-0.8-4-1.7-7.9c-3.6 8-7.9 15.6-13.2 22.7-0.8 1.1-0.5 2.6 0.6 3.5z"/>
						<path id="Layer" className="s1" d="m821.3 111.5c9.6-6.5 18.1-14.1 25.6-22.9 0.9-1 0.8-2.5-0.2-3.4-1.1-0.9-2.6-0.8-3.5 0.2-8 9.3-17.3 17.4-27.6 24.1-0.7 0.4-1.1 1.2-1.1 2 0 0.9 0.4 1.6 1.1 2.1 20.8 13.3 36.8 32.2 46.7 54q0.9-3.8 1.7-7.7c-9.8-19.2-24.3-35.9-42.7-48.4z"/>
						<path id="Layer" className="s1" d="m841.4 111.5c9.9-8.6 18.5-18.4 25.6-29.2q-0.4-3.9-0.9-7.7c-7.9 13.2-18 25-30.1 35-0.5 0.5-0.8 1.2-0.8 1.9 0 0.7 0.3 1.4 0.8 1.9 12 10 22.1 21.8 30 34.9q0.5-3.8 0.9-7.7c-7-10.7-15.6-20.5-25.5-29.1z"/>
						<path id="Layer" className="s1" d="m868.2 101.4q-0.1-3.9-0.3-7.6-6.3 8.4-13.9 16c-0.4 0.5-0.7 1.1-0.7 1.7 0 0.7 0.3 1.3 0.7 1.8 5.1 5 9.7 10.3 13.9 15.9q0.2-3.7 0.3-7.5c-2.8-3.5-5.8-6.9-9-10.2 3.2-3.2 6.2-6.6 9-10.1z"/>
						<path id="Layer" className="s1" d="m740.3 210.7c-36.2-9.1-64.5-37.2-73.9-73.3-2.4-9.1-10.7-15.5-20.1-15.5h-11.1q0.1 2.5 0.2 4.9h10.9c7.2 0 13.5 4.9 15.3 11.9 9.9 37.8 39.6 67.2 77.5 76.8 6.4 1.6 10.9 7.4 10.9 14.1v46.3q0.7 0 1.5 0 0.1 0 0.2 0 0.1 0 0.2 0c1 0 2 0 3 0v-46.3c0-9-6-16.7-14.6-18.9z"/>
						<path id="Layer" className="s1" d="m737.3 222.6c-40.5-10.2-72.2-41.7-82.8-82.1-1-3.7-4.3-6.3-8.2-6.3h-10.5c0.2 1.6 0.3 3.2 0.5 4.9h10c1.6 0 3.1 1.1 3.5 2.7 11 42.1 44.1 74.9 86.3 85.5 1 0.3 1.6 1.2 1.6 2.3v1.6c0 1.3 1.1 2.4 2.5 2.4 1.3 0 2.5-1.1 2.5-2.4v-1.6c-0.1-3.3-2.3-6.2-5.4-7z"/>
						<path id="Layer" className="s1" d="m740.2 240.6c-1.4 0-2.5 1.1-2.5 2.4v31.8q2.5 0.4 4.9 0.7v-32.5c0-1.3-1.1-2.4-2.4-2.4z"/>
						<path id="Layer" className="s1" d="m670.9 199.8c1-0.9 1-2.5 0.1-3.5-12.3-13.8-21.7-30.4-27.1-48.1-0.3-1.1-1.2-1.8-2.3-1.8h-4.4c0.2 1.7 0.4 3.3 0.6 4.9h2c5.6 17.8 15.1 34.4 27.6 48.3 0.9 1 2.4 1.1 3.5 0.2z"/>
						<path id="Layer" className="s1" d="m728.6 232.9c-17.5-5.3-33.3-14.1-47-26.2-1.1-0.9-2.6-0.8-3.5 0.2-0.9 1.1-0.8 2.6 0.2 3.5 13.8 12.2 29.7 21.1 47.2 26.7v34.7q2.4 0.8 4.9 1.4v-38c0-1-0.7-2-1.8-2.3z"/>
						<path id="Layer" className="s1" d="m716.5 241.8c-30.4-11.4-55.6-33-71.6-60.8q3.4 10.6 7.7 20.1c15.8 19.8 36.6 35.3 60.6 44.6v21.1q2.5 1.2 4.9 2.3v-25.1c0-1-0.6-1.9-1.6-2.3z"/>
						<path id="Layer" className="s1" d="m704.4 250c-13.4-5.9-25.8-13.6-36.8-22.9 4.1 5.7 8.4 10.9 13 15.6 6.4 4.2 13.2 8 20.4 11.2v6q2.4 1.6 4.9 3v-10.6c0-1-0.6-1.9-1.5-2.3z"/>
						<path id="Layer" className="s1" d="m666.4 85.6c9.4-36.1 37.7-64.2 73.9-73.3 8.6-2.2 14.6-9.9 14.6-18.9v-47c-1 0-2-0.1-3 0q-0.1 0-0.2 0-0.1 0-0.2 0-0.8 0-1.5 0v47c0 6.7-4.5 12.5-10.9 14.1-37.9 9.6-67.6 39-77.5 76.8-1.8 7-8.1 11.9-15.3 11.9h-11q-0.1 2.4-0.1 4.9h11.1c9.4 0 17.7-6.4 20.1-15.5z"/>
						<path id="Layer" className="s1" d="m678.6 32.9c0.5 0.5 1.1 0.7 1.7 0.7 0.6 0 1.3-0.3 1.8-0.8 14.9-15.8 34-27 55.2-32.4 3.1-0.8 5.3-3.6 5.3-7v-13c0-1.3-1.1-2.4-2.4-2.4-1.4 0-2.5 1.1-2.5 2.4v13c0 1.1-0.7 2-1.6 2.3-22.1 5.6-42 17.2-57.6 33.8-0.9 0.9-0.9 2.5 0.1 3.4z"/>
						<path id="Layer" className="s1" d="m654.5 82.5c3.7-14 9.8-27 18.4-38.6 0.8-1.1 0.5-2.7-0.6-3.5-1.1-0.8-2.6-0.5-3.4 0.6-8.9 12.1-15.3 25.7-19.1 40.2-0.5 1.6-1.9 2.7-3.5 2.7h-10.1c-0.1 1.7-0.3 3.3-0.4 4.9h10.5c3.9 0 7.2-2.6 8.2-6.3z"/>
						<path id="Layer" className="s1" d="m740.2-26.9c1.3 0 2.4-1.1 2.4-2.5v-23.8q-2.4 0.3-4.9 0.7v23.1c0 1.4 1.1 2.5 2.5 2.5z"/>
						<path id="Layer" className="s1" d="m643.9 74.8c12.3-40.7 44-72.4 84.8-84.7 1-0.3 1.7-1.3 1.7-2.4v-38.6q-2.5 0.6-4.9 1.4v35.4c-40.9 13-72.8 44.9-85.7 85.8h-2c-0.3 1.6-0.5 3.2-0.7 4.9h4.5c1.1 0 2-0.7 2.3-1.8z"/>
						<path id="Layer" className="s1" d="m716.5-18.7c1-0.4 1.6-1.3 1.6-2.3v-25.7q-2.4 1-4.9 2.2v21.8c-24.4 9.5-45.5 25.4-61.3 45.6q-4.2 9.4-7.5 19.9c16-28.2 41.4-50.1 72.1-61.5z"/>
						<path id="Layer" className="s1" d="m705.9-29.3v-11.3q-2.5 1.4-4.9 3v6.7c-7.8 3.5-15.2 7.7-22.1 12.3q-6.7 7.1-12.6 15.6c11.4-9.7 24.1-17.9 38.1-24 0.9-0.4 1.5-1.3 1.5-2.3z"/>
					</g>
				</g>
			</g>
			<g id="Layer">
				<g id="Clip-Path" clipPath="url(#cp3)">
					<g id="Layer">
						<path id="Layer" className="s1" d="m662.8 119.3c1.4 0 2.5-1.1 2.5-2.5 0-1.3-1.1-2.4-2.5-2.4h-27.7q0 1 0 2.1 0 1.4-0.1 2.8z"/>
						<path id="Layer" className="s1" d="m684.1 68.7c-0.7 1.2-0.3 2.7 0.9 3.4q0.6 0.3 1.2 0.3c0.9 0 1.7-0.4 2.2-1.2 13-21.9 34.7-37.3 59.6-42.1 11.5-2.3 19.8-12.3 19.8-23.8v-52.1q-2.5-0.5-4.9-0.8v52.9c0 9.2-6.7 17.1-15.8 18.9-26.3 5.2-49.2 21.4-63 44.5z"/>
						<path id="Layer" className="s1" d="m680.9 80.3c-1.2-0.5-2.6 0.1-3.2 1.4-4.5 11.1-6.7 23-6.7 35.1 0 21.6 7.1 42 20.5 58.8 0.5 0.6 1.2 0.9 1.9 0.9 0.6 0 1.1-0.1 1.6-0.5 1-0.8 1.2-2.4 0.3-3.4-12.7-16-19.4-35.3-19.4-55.8 0-11.5 2.1-22.7 6.4-33.3 0.5-1.3-0.1-2.7-1.4-3.2z"/>
						<path id="Layer" className="s1" d="m749.8 204.9c-16-2.8-31-10-43.3-20.7-1-0.9-2.6-0.8-3.5 0.2-0.9 1.1-0.7 2.6 0.3 3.5 12.9 11.3 28.7 18.9 45.6 21.8 8.1 1.5 14 8.5 14 16.8v54q2.4-0.3 4.9-0.8v-53.2c0-10.7-7.6-19.8-18-21.6z"/>
						<path id="Layer" className="s1" d="m760.9 33.2c-1.1 0.7-1.6 2.2-0.9 3.3 0.5 0.8 1.3 1.3 2.1 1.3q0.7 0 1.3-0.4c9.1-5.2 15.4-14.5 16.9-25 0.2-1.4-0.8-2.6-2.1-2.8-1.4-0.2-2.6 0.7-2.8 2.1-1.2 9-6.7 17-14.5 21.5z"/>
						<path id="Layer" className="s1" d="m778.2 1.3c1.3-0.1 2.4-1.2 2.4-2.5v-42q-2.4-0.9-4.9-1.6v43.6c0 1.3 1.1 2.4 2.5 2.4z"/>
						<path id="Layer" className="s1" d="m752 192.2c-3.2-0.5-6.5-1.3-9.6-2.2-1.3-0.4-2.6 0.3-3 1.6-0.4 1.3 0.3 2.7 1.6 3 3.3 1.1 6.8 1.9 10.2 2.4 14.2 2.4 24.5 14.6 24.5 29.1v51.7q2.5-0.7 4.9-1.6v-50.1c0-16.9-12-31.1-28.6-33.9z"/>
						<path id="Layer" className="s1" d="m719.8 184.4c2.6 1.7 5.3 3.4 8 4.8q0.5 0.2 1.1 0.2c0.9 0 1.7-0.4 2.2-1.3 0.6-1.2 0.1-2.6-1.1-3.3-2.5-1.3-5-2.8-7.5-4.5-21.1-14.3-33.8-38-33.8-63.5 0-27.7 15.1-53.3 39.4-66.9 6.3-3.5 12.6-6 19.3-7.6 1.3-0.3 2.1-1.6 1.8-2.9-0.3-1.3-1.6-2.1-2.9-1.8-7.1 1.6-13.9 4.3-20.6 8.1-25.8 14.4-41.9 41.7-41.9 71.1 0 27.1 13.5 52.3 36 67.6z"/>
						<path id="Layer" className="s1" d="m754 180.1c-8.8-1.4-16.9-4.7-24.6-10-17.8-12-28.4-31.9-28.4-53.3 0-23.2 12.6-44.8 33-56.2 6.6-3.6 13.2-6 20.2-7.1 22.4-3.7 38.7-23 38.7-46v-45.4q-2.5-1.3-4.9-2.4v47.8c0 20.5-14.6 37.9-34.6 41.1-7.6 1.3-14.7 3.8-21.8 7.7-21.9 12.3-35.5 35.5-35.5 60.5 0 23 11.4 44.5 30.5 57.4 8.4 5.7 17.1 9.2 26.6 10.7 20.2 3.4 34.8 20.7 34.8 41.2v47.1q2.4-1.1 4.9-2.4v-44.7c0-22.9-16.4-42.2-38.9-46z"/>
						<path id="Layer" className="s1" d="m714.5 96.9c-1.3-0.4-2.7 0.3-3.1 1.5-2 6-3.1 12.2-3.1 18.4 0 18.9 9.4 36.6 25.2 47.3 6.9 4.6 14 7.5 21.8 8.8 12 2 23.2 8.1 31.3 17.2 0.4 0.5 1.1 0.8 1.8 0.8 0.6 0 1.2-0.2 1.6-0.6 1-0.9 1.1-2.5 0.2-3.5-8.8-9.9-21-16.6-34.1-18.8-7.1-1.1-13.6-3.8-19.8-8-14.4-9.7-23-25.9-23-43.2 0-5.7 0.9-11.4 2.8-16.8 0.4-1.2-0.3-2.6-1.6-3.1z"/>
						<path id="Layer" className="s1" d="m796.8 200.7c-1.3 0.6-1.8 2-1.3 3.3 3.2 6.9 4.7 14.4 4.7 22.1v40.5q2.5-1.6 4.9-3.3v-37.2c0-8.4-1.7-16.5-5.1-24.1-0.5-1.3-2-1.8-3.2-1.3z"/>
						<path id="Layer" className="s1" d="m795.4 35.5c1.2 0.6 2.7 0.2 3.3-1.1 4.2-8.2 6.4-17.5 6.4-26.9v-37.9q-2.4-1.7-4.9-3.2v41.1c0 8.6-2 17.2-5.9 24.7-0.6 1.2-0.1 2.7 1.1 3.3z"/>
						<path id="Layer" className="s1" d="m740 71.3c5.3-2.9 10.6-4.8 16.2-5.7 14.5-2.4 27.5-10.1 36.5-21.7 0.9-1.1 0.7-2.6-0.4-3.5-1.1-0.8-2.6-0.6-3.4 0.5-8.3 10.6-20.2 17.6-33.5 19.8-6.2 1-12 3.1-17.8 6.3-8 4.5-14.8 10.9-19.9 18.5-0.7 1.2-0.4 2.7 0.7 3.4q0.7 0.4 1.4 0.4c0.8 0 1.6-0.4 2-1.1 4.6-6.9 10.9-12.8 18.2-16.9z"/>
						<path id="Layer" className="s1" d="m758.1 156c-5.3-0.9-10.2-2.9-15-6.1-11-7.5-17.6-19.8-17.6-33.1 0-14.4 7.9-27.7 20.5-34.8 4-2.2 8-3.7 12.2-4.3 34.3-5.6 59.2-35.2 59.2-70.2v-28q-2.4-2.2-4.9-4.3v32.3c0 32.6-23.2 60.1-55.1 65.3-4.8 0.8-9.3 2.4-13.8 5-14.2 7.9-23 22.9-23 39 0 14.9 7.4 28.8 19.8 37.1 5.3 3.7 10.8 5.9 16.8 6.9 32 5.3 55.3 32.8 55.3 65.3v31.6q2.5-2 4.9-4.3v-27.3c0-34.9-25-64.4-59.3-70.1z"/>
						<path id="Layer" className="s1" d="m796.7 67.9c-1 0.8-1.2 2.4-0.3 3.4 0.9 1.1 2.4 1.2 3.5 0.3 18.9-15.8 29.7-39.2 29.7-64.1v-15q-2.4-2.9-4.9-5.6v20.6c0 23.5-10.2 45.5-28 60.4z"/>
						<path id="Layer" className="s1" d="m760.1 143.9c-3.6-0.6-6.8-1.9-10.1-4.2-7.7-5.2-12.3-13.7-12.3-22.9 0-9.9 5.5-19.2 14.3-24.1 2.7-1.5 5.4-2.5 8.1-2.9 9.5-1.6 18.4-4.7 26.7-9.2 1.2-0.7 1.6-2.2 1-3.4-0.7-1.2-2.2-1.6-3.4-0.9-7.8 4.2-16.2 7.2-25.1 8.6-3.3 0.6-6.4 1.7-9.7 3.5-10.3 5.8-16.8 16.7-16.8 28.4 0 10.8 5.4 20.9 14.4 27 3.9 2.6 7.8 4.2 12.1 4.9 37.9 6.3 65.4 38.9 65.4 77.4v19.9q2.5-2.7 4.9-5.6v-14.3c0-40.9-29.2-75.5-69.5-82.2z"/>
						<path id="Layer" className="s1" d="m762.1 131.8c-1.9-0.3-3.4-1-5.3-2.2-4.2-2.9-6.8-7.7-6.8-12.8 0-5.5 3-10.7 8-13.4 1.5-0.9 2.8-1.4 4.1-1.6 45.5-7.4 78.7-46 79.7-92.2q-2.3-3.8-4.8-7.4v5.3c0 44.7-31.8 82.3-75.7 89.5-1.9 0.3-3.7 1-5.7 2.1-6.5 3.6-10.5 10.4-10.5 17.7 0 6.7 3.4 13 9 16.8 2.5 1.7 4.7 2.6 7.2 3 43.9 7.3 75.7 44.9 75.7 89.5v4.6q2.5-3.6 4.8-7.3c-1.3-45.8-34.4-84.1-79.7-91.6z"/>
						<path id="Layer" className="s1" d="m763.7 109.1c-0.4 0-0.9 0-2.1 0.7-2.6 1.5-4.3 4.2-4.3 7 0 2.7 1.4 5.1 3.7 6.7 1.1 0.7 1.6 0.9 2.3 1 24.8 4.1 47.1 17.1 62.9 36.4 0.8 1.1 2.4 1.2 3.4 0.4 1.1-0.9 1.2-2.4 0.4-3.5-16.6-20.3-39.9-33.8-65.8-38.1q-0.1 0-0.5-0.3c-0.9-0.6-1.4-1.6-1.4-2.6 0-1.1 0.6-2.1 1.7-2.7 0.2-0.1 0.3-0.2 0.2-0.2 44.9-7.4 79.1-41.6 87.7-84.8q-1.7-4-3.7-7.8c-5.8 44.5-39.7 80.4-84.5 87.8z"/>
						<path id="Layer" className="s1" d="m835.7 170.1c-1.1 0.7-1.5 2.2-0.8 3.3 6.9 11.8 11.4 24.8 13.3 38.3q1.9-3.8 3.6-7.7c-2.4-11.6-6.7-22.8-12.7-33.1-0.7-1.1-2.2-1.5-3.4-0.8z"/>
						<path id="Layer" className="s1" d="m789.3 114.6c-1.3 0.5-1.9 1.9-1.4 3.1 0.5 1.3 1.9 1.9 3.2 1.4 32.5-12.9 56.7-38.7 68.1-70.4q-1.2-3.9-2.6-7.7c-9.8 33.1-34.1 60.4-67.3 73.6z"/>
						<path id="Layer" className="s1" d="m809.1 123.6c-1.2-0.7-2.7-0.4-3.4 0.8-0.7 1.1-0.3 2.6 0.8 3.3 24.5 15 41.9 38 50 64.6q1.3-3.8 2.6-7.8c-9.1-25-26.4-46.5-50-60.9z"/>
						<path id="Layer" className="s1" d="m849.8 86.6c1.1 0.8 2.6 0.5 3.4-0.6q6.2-8.4 10.9-17.7-0.8-4-1.7-7.9c-3.6 8-7.9 15.6-13.2 22.8-0.8 1-0.5 2.6 0.6 3.4z"/>
						<path id="Layer" className="s1" d="m821.3 116.9c9.6-6.5 18.1-14.2 25.6-22.9 0.9-1.1 0.8-2.6-0.2-3.5-1.1-0.9-2.6-0.8-3.5 0.3-8 9.3-17.3 17.4-27.6 24-0.7 0.4-1.1 1.2-1.1 2.1 0 0.8 0.4 1.6 1.1 2 20.8 13.4 36.8 32.2 46.7 54q0.9-3.8 1.7-7.7c-9.8-19.2-24.3-35.8-42.7-48.3z"/>
						<path id="Layer" className="s1" d="m841.4 116.9c9.9-8.6 18.5-18.5 25.6-29.2q-0.4-3.9-0.9-7.7c-7.9 13.1-18 25-30.1 35-0.5 0.4-0.8 1.1-0.8 1.9 0 0.7 0.3 1.4 0.8 1.8 12 10 22.1 21.8 30 34.9q0.5-3.8 0.9-7.7c-7-10.7-15.6-20.5-25.5-29z"/>
						<path id="Layer" className="s1" d="m868.2 106.7q-0.1-3.8-0.3-7.6-6.3 8.4-13.9 16c-0.4 0.5-0.7 1.1-0.7 1.8 0 0.6 0.3 1.2 0.7 1.7 5.1 5 9.7 10.3 13.9 15.9q0.2-3.7 0.3-7.5c-2.8-3.5-5.8-6.9-9-10.1q4.8-5 9-10.2z"/>
						<path id="Layer" className="s1" d="m740.3 216c-36.2-9.1-64.5-37.2-73.9-73.3-2.4-9.1-10.7-15.5-20.1-15.5h-11.1q0.1 2.5 0.2 4.9h10.9c7.2 0 13.5 4.9 15.3 11.9 9.9 37.8 39.6 67.2 77.5 76.8 6.4 1.6 10.9 7.4 10.9 14.1v46.3q0.7 0.1 1.5 0.1 0.1 0 0.2 0 0.1 0 0.2 0c1 0 2-0.1 3-0.1v-46.3c0-8.9-6-16.7-14.6-18.9z"/>
						<path id="Layer" className="s1" d="m737.3 227.9c-40.5-10.2-72.2-41.7-82.8-82.1-1-3.7-4.3-6.3-8.2-6.3h-10.5c0.2 1.6 0.3 3.3 0.5 4.9h10c1.6 0 3.1 1.1 3.5 2.7 11 42.1 44.1 74.9 86.3 85.6 1 0.2 1.6 1.1 1.6 2.2v1.6c0 1.3 1.1 2.4 2.5 2.4 1.3 0 2.5-1.1 2.5-2.4v-1.6c-0.1-3.3-2.3-6.2-5.4-7z"/>
						<path id="Layer" className="s1" d="m740.2 245.9c-1.4 0-2.5 1.1-2.5 2.4v31.8q2.5 0.4 4.9 0.7v-32.5c0-1.3-1.1-2.4-2.4-2.4z"/>
						<path id="Layer" className="s1" d="m670.9 205.1c1-0.9 1-2.5 0.1-3.5-12.3-13.7-21.7-30.4-27.1-48.1-0.3-1-1.2-1.7-2.3-1.7h-4.4c0.2 1.6 0.4 3.2 0.6 4.8h2c5.6 17.8 15.1 34.5 27.6 48.3 0.9 1 2.4 1.1 3.5 0.2z"/>
						<path id="Layer" className="s1" d="m728.6 238.2c-17.5-5.2-33.3-14.1-47-26.2-1.1-0.9-2.6-0.8-3.5 0.3-0.9 1-0.8 2.5 0.2 3.4 13.8 12.2 29.7 21.1 47.2 26.7v34.7q2.4 0.8 4.9 1.4v-37.9c0-1.1-0.7-2.1-1.8-2.4z"/>
						<path id="Layer" className="s1" d="m716.5 247.1c-30.4-11.4-55.6-33-71.6-60.7q3.4 10.6 7.7 20.1c15.8 19.7 36.6 35.2 60.6 44.6v21.1q2.5 1.1 4.9 2.2v-25c0-1-0.6-2-1.6-2.3z"/>
						<path id="Layer" className="s1" d="m704.4 255.4c-13.4-5.9-25.8-13.7-36.8-23 4.1 5.7 8.4 10.9 13 15.7 6.4 4.1 13.2 7.9 20.4 11.1v6q2.4 1.6 4.9 3v-10.6c0-1-0.6-1.8-1.5-2.2z"/>
						<path id="Layer" className="s1" d="m666.4 90.9c9.4-36.1 37.7-64.2 73.9-73.3 8.6-2.2 14.6-9.9 14.6-18.9v-46.9c-1-0.1-2-0.1-3-0.1q-0.1 0-0.2 0-0.1 0-0.2 0-0.8 0-1.5 0v47c0 6.7-4.5 12.5-10.9 14.2-37.9 9.5-67.6 39-77.5 76.8-1.8 7-8.1 11.8-15.3 11.8h-11q-0.1 2.5-0.1 4.9h11.1c9.4 0 17.7-6.4 20.1-15.5z"/>
						<path id="Layer" className="s1" d="m678.6 38.2c0.5 0.5 1.1 0.7 1.7 0.7 0.6 0 1.3-0.2 1.8-0.7 14.9-15.9 34-27.1 55.2-32.4 3.1-0.8 5.3-3.7 5.3-7.1v-13c0-1.3-1.1-2.4-2.4-2.4-1.4 0-2.5 1.1-2.5 2.4v13c0 1.1-0.7 2.1-1.6 2.3-22.1 5.6-42 17.3-57.6 33.8-0.9 1-0.9 2.5 0.1 3.4z"/>
						<path id="Layer" className="s1" d="m654.5 87.8c3.7-14 9.8-27 18.4-38.6 0.8-1.1 0.5-2.7-0.6-3.4-1.1-0.9-2.6-0.6-3.4 0.5-8.9 12.1-15.3 25.7-19.1 40.3-0.5 1.5-1.9 2.7-3.5 2.7h-10.1c-0.1 1.6-0.3 3.2-0.4 4.9h10.5c3.9 0 7.2-2.6 8.2-6.4z"/>
						<path id="Layer" className="s1" d="m740.2-21.6c1.3 0 2.4-1.1 2.4-2.5v-23.7q-2.4 0.2-4.9 0.6v23.1c0 1.4 1.1 2.5 2.5 2.5z"/>
						<path id="Layer" className="s1" d="m643.9 80.2c12.3-40.8 44-72.5 84.8-84.8 1-0.3 1.7-1.2 1.7-2.3v-38.7q-2.5 0.6-4.9 1.4v35.5c-40.9 12.9-72.8 44.8-85.7 85.7h-2c-0.3 1.6-0.5 3.3-0.7 4.9h4.5c1.1 0 2-0.7 2.3-1.7z"/>
						<path id="Layer" className="s1" d="m716.5-13.4c1-0.4 1.6-1.3 1.6-2.3v-25.7q-2.4 1-4.9 2.2v21.8c-24.4 9.5-45.5 25.4-61.3 45.6q-4.2 9.5-7.5 19.9c16-28.1 41.4-50 72.1-61.5z"/>
						<path id="Layer" className="s1" d="m705.9-24v-11.3q-2.5 1.5-4.9 3.1v6.6c-7.8 3.5-15.2 7.7-22.1 12.3q-6.7 7.1-12.6 15.6c11.4-9.7 24.1-17.9 38.1-24 0.9-0.4 1.5-1.3 1.5-2.3z"/>
					</g>
				</g>
			</g>
			<g id="Layer">
				<path id="Layer" fillRule="evenodd" className="s1" d="m842.4 4.2v52.8c0 2.4-1.9 4.3-4.2 4.3h-173.6c-2.4 0-4.3-1.9-4.3-4.3v-52.8c0-2.3 1.9-4.2 4.3-4.2h173.6c2.3 0 4.2 1.9 4.2 4.2zm-177.8 0v52.8h173.6v-52.8z"/>
			</g>
			<g id="Layer">
				<path id="Layer" fillRule="evenodd" className="s1" d="m816.8 90.5v51c0 2.4-1.9 4.3-4.3 4.3h-147.9c-2.4 0-4.3-1.9-4.3-4.3v-51c0-2.4 1.9-4.3 4.3-4.3h147.9c2.4 0 4.3 1.9 4.3 4.3zm-152.2 0v51h147.9v-51z"/>
			</g>
			<g id="Layer">
				<path id="Layer" fillRule="evenodd" className="s1" d="m842.4 175v52.8c0 2.3-1.9 4.3-4.2 4.3h-173.6c-2.4 0-4.3-2-4.3-4.3v-52.8c0-2.3 1.9-4.3 4.3-4.3h173.6c2.3 0 4.2 2 4.2 4.3zm-177.8 0v52.8h173.6v-52.8z"/>
			</g>
			<g id="Layer">
				<g id="Clip-Path" clipPath="url(#cp4)">
					<g id="Layer">
						<path id="Layer" className="s1" d="m870.1 108.6c1.3 0 2.4-1.1 2.4-2.4 0-1.4-1.1-2.5-2.4-2.5h-27.8q0 1.1 0.1 2.1 0 1.4-0.1 2.8z"/>
						<path id="Layer" className="s1" d="m891.4 58.1c-0.7 1.1-0.3 2.6 0.9 3.3q0.6 0.4 1.2 0.4c0.9 0 1.7-0.5 2.1-1.2 13.1-21.9 34.8-37.3 59.7-42.2 11.4-2.2 19.7-12.2 19.7-23.7v-52.2q-2.4-0.4-4.9-0.8v53c0 9.1-6.6 17.1-15.8 18.9-26.2 5.2-49.1 21.4-62.9 44.5z"/>
						<path id="Layer" className="s1" d="m888.2 69.6c-1.2-0.4-2.7 0.2-3.2 1.4-4.5 11.2-6.8 23-6.8 35.2 0 21.6 7.1 41.9 20.6 58.8 0.5 0.6 1.2 0.9 1.9 0.9q0.8 0 1.5-0.5c1.1-0.9 1.3-2.4 0.4-3.5-12.7-15.9-19.4-35.2-19.4-55.7 0-11.5 2.1-22.8 6.4-33.4 0.5-1.2-0.1-2.7-1.4-3.2z"/>
						<path id="Layer" className="s1" d="m957 194.3c-16-2.8-30.9-10-43.2-20.7-1-0.9-2.6-0.8-3.5 0.2-0.9 1-0.8 2.6 0.2 3.5 13 11.3 28.8 18.8 45.7 21.8 8.1 1.4 13.9 8.5 13.9 16.8v54q2.5-0.3 4.9-0.8v-53.3c0-10.6-7.5-19.7-18-21.5z"/>
						<path id="Layer" className="s1" d="m968.2 22.5c-1.1 0.7-1.6 2.2-0.9 3.4 0.5 0.8 1.3 1.2 2.1 1.2q0.7 0 1.3-0.3c9.1-5.2 15.4-14.6 16.9-25.1 0.2-1.3-0.8-2.6-2.1-2.8-1.4-0.2-2.6 0.8-2.8 2.1-1.2 9-6.7 17.1-14.5 21.5z"/>
						<path id="Layer" className="s1" d="m985.5-9.4c1.3 0 2.4-1.1 2.4-2.4v-42.1q-2.4-0.8-4.9-1.6v43.7c0 1.3 1.1 2.4 2.5 2.4z"/>
						<path id="Layer" className="s1" d="m959.3 181.6c-3.2-0.6-6.5-1.3-9.6-2.3-1.3-0.4-2.6 0.4-3 1.6-0.4 1.3 0.3 2.7 1.6 3.1 3.3 1 6.7 1.8 10.2 2.4 14.2 2.4 24.5 14.6 24.5 29.1v51.6q2.5-0.7 4.9-1.6v-50c0-16.9-12-31.2-28.6-33.9z"/>
						<path id="Layer" className="s1" d="m927.1 173.7c2.6 1.8 5.3 3.4 8 4.8q0.5 0.3 1.1 0.3c0.9 0 1.7-0.5 2.2-1.3 0.6-1.2 0.1-2.7-1.1-3.3-2.5-1.3-5-2.9-7.5-4.5-21.1-14.3-33.8-38.1-33.8-63.5 0-27.7 15.1-53.3 39.3-66.9 6.4-3.6 12.7-6 19.4-7.6 1.3-0.3 2.1-1.6 1.8-2.9-0.3-1.4-1.6-2.2-2.9-1.9-7.1 1.7-13.9 4.3-20.6 8.1-25.9 14.4-41.9 41.7-41.9 71.2 0 27.1 13.5 52.3 36 67.5z"/>
						<path id="Layer" className="s1" d="m961.3 169.5c-8.8-1.5-16.9-4.8-24.6-10-17.8-12-28.4-32-28.4-53.3 0-23.3 12.6-44.8 33-56.2 6.6-3.7 13.2-6 20.2-7.2 22.4-3.6 38.7-23 38.7-45.9v-45.4q-2.5-1.3-4.9-2.4v47.8c0 20.5-14.6 37.8-34.6 41.1-7.6 1.2-14.7 3.8-21.8 7.7-21.9 12.3-35.5 35.4-35.5 60.5 0 23 11.4 44.4 30.5 57.4 8.4 5.6 17.1 9.1 26.6 10.7 20.2 3.3 34.8 20.7 34.8 41.2v47.1q2.4-1.2 4.9-2.4v-44.7c0-22.9-16.4-42.3-38.9-46z"/>
						<path id="Layer" className="s1" d="m921.8 86.3c-1.3-0.5-2.7 0.2-3.1 1.5-2 5.9-3.1 12.1-3.1 18.4 0 18.9 9.4 36.6 25.2 47.2 6.9 4.6 14 7.5 21.8 8.8 12 2 23.1 8.1 31.2 17.2 0.5 0.6 1.2 0.8 1.9 0.8 0.6 0 1.1-0.2 1.6-0.6 1-0.9 1.1-2.4 0.2-3.4-8.8-10-21-16.6-34.1-18.8-7.1-1.2-13.6-3.8-19.8-8-14.4-9.8-23.1-25.9-23.1-43.2 0-5.8 1-11.4 2.8-16.8 0.5-1.3-0.2-2.7-1.5-3.1z"/>
						<path id="Layer" className="s1" d="m1004.1 190.1c-1.3 0.5-1.8 2-1.3 3.2 3.1 7 4.7 14.5 4.7 22.2v40.4q2.5-1.5 4.9-3.2v-37.2c0-8.4-1.7-16.6-5.1-24.2-0.6-1.2-2-1.8-3.2-1.2z"/>
						<path id="Layer" className="s1" d="m1002.7 24.9c1.2 0.6 2.7 0.1 3.3-1.1 4.2-8.2 6.4-17.6 6.4-26.9v-37.9q-2.4-1.7-4.9-3.3v41.2c0 8.6-2 17.1-5.9 24.7-0.6 1.2-0.1 2.7 1.1 3.3z"/>
						<path id="Layer" className="s1" d="m947.3 60.7c5.3-3 10.6-4.9 16.2-5.8 14.5-2.4 27.5-10.1 36.5-21.7 0.8-1 0.7-2.6-0.4-3.4-1.1-0.8-2.6-0.7-3.5 0.4-8.2 10.6-20.1 17.7-33.4 19.9-6.2 1-12 3.1-17.8 6.3-8 4.5-14.8 10.9-19.9 18.5-0.7 1.1-0.4 2.6 0.7 3.4q0.7 0.4 1.4 0.4c0.8 0 1.6-0.4 2-1.1 4.6-7 10.9-12.8 18.2-16.9z"/>
						<path id="Layer" className="s1" d="m965.4 145.3c-5.3-0.9-10.2-2.9-15-6.1-11-7.4-17.6-19.8-17.6-33 0-14.4 7.8-27.7 20.5-34.8 4-2.3 8-3.7 12.1-4.4 34.4-5.6 59.3-35.1 59.3-70.1v-28q-2.5-2.3-4.9-4.3v32.3c0 32.6-23.2 60.1-55.1 65.3-4.8 0.8-9.3 2.4-13.8 4.9-14.2 7.9-23 22.9-23 39.1 0 14.8 7.4 28.7 19.7 37.1 5.4 3.6 10.9 5.9 16.9 6.9 32 5.3 55.3 32.8 55.3 65.3v31.6q2.4-2.1 4.9-4.3v-27.3c0-35-25-64.5-59.3-70.2z"/>
						<path id="Layer" className="s1" d="m1004 57.2c-1 0.9-1.2 2.4-0.3 3.5 0.9 1 2.4 1.1 3.5 0.3 18.9-15.9 29.7-39.2 29.7-64.2v-14.9q-2.4-2.9-4.9-5.6v20.5c0 23.5-10.2 45.5-28 60.4z"/>
						<path id="Layer" className="s1" d="m967.4 133.2c-3.6-0.6-6.8-1.9-10.1-4.1-7.7-5.2-12.3-13.8-12.3-22.9 0-10 5.5-19.2 14.3-24.1 2.7-1.6 5.4-2.5 8.1-3 9.4-1.5 18.4-4.6 26.7-9.2 1.2-0.6 1.6-2.1 0.9-3.3-0.6-1.2-2.1-1.6-3.3-1-7.8 4.3-16.2 7.2-25.1 8.7-3.3 0.5-6.5 1.7-9.7 3.5-10.4 5.8-16.8 16.7-16.8 28.4 0 10.8 5.4 20.8 14.4 26.9 3.9 2.7 7.8 4.2 12.1 5 37.9 6.3 65.4 38.8 65.4 77.4v19.9q2.5-2.7 4.9-5.6v-14.3c0-41-29.2-75.6-69.5-82.3z"/>
						<path id="Layer" className="s1" d="m969.4 121.1c-1.9-0.3-3.4-0.9-5.3-2.2-4.2-2.9-6.8-7.7-6.8-12.7 0-5.6 3-10.7 8-13.5 1.5-0.8 2.8-1.3 4.1-1.5 45.5-7.5 78.7-46.1 79.7-92.3q-2.3-3.8-4.8-7.3v5.2c0 44.7-31.8 82.4-75.7 89.5-1.9 0.3-3.7 1-5.7 2.2-6.5 3.6-10.5 10.4-10.5 17.7 0 6.7 3.4 12.9 9 16.8 2.4 1.6 4.7 2.5 7.2 2.9 43.8 7.3 75.7 45 75.7 89.6v4.5q2.5-3.5 4.8-7.3c-1.3-45.7-34.4-84-79.7-91.6z"/>
						<path id="Layer" className="s1" d="m971 98.4c-0.4 0-0.9 0.1-2.2 0.8-2.6 1.4-4.2 4.1-4.2 7 0 2.6 1.4 5.1 3.7 6.6 1.1 0.8 1.6 1 2.3 1.1 24.8 4.1 47.1 17 62.9 36.4 0.8 1 2.4 1.2 3.4 0.3 1.1-0.8 1.2-2.4 0.4-3.4-16.6-20.3-40-33.9-65.8-38.1q-0.1-0.1-0.5-0.3c-0.9-0.7-1.5-1.6-1.5-2.6 0-1.1 0.7-2.2 1.7-2.8 0.3-0.1 0.4-0.1 0.3-0.1 44.8-7.4 79-41.6 87.7-84.9q-1.8-4-3.7-7.8c-5.8 44.5-39.7 80.4-84.5 87.8z"/>
						<path id="Layer" className="s1" d="m1043 159.4c-1.1 0.7-1.5 2.2-0.8 3.4 6.9 11.7 11.4 24.7 13.3 38.3q1.9-3.8 3.6-7.7c-2.4-11.7-6.7-22.9-12.7-33.1-0.7-1.2-2.2-1.6-3.4-0.9z"/>
						<path id="Layer" className="s1" d="m996.6 103.9c-1.3 0.5-1.9 1.9-1.4 3.2 0.5 1.3 1.9 1.9 3.2 1.4 32.4-12.9 56.7-38.8 68.1-70.4q-1.2-4-2.6-7.8c-9.9 33.2-34.1 60.4-67.3 73.6z"/>
						<path id="Layer" className="s1" d="m1016.4 112.9c-1.2-0.7-2.7-0.3-3.4 0.8-0.7 1.2-0.3 2.7 0.8 3.4 24.5 15 41.9 37.9 50 64.6q1.3-3.8 2.6-7.8c-9.2-25.1-26.4-46.6-50-61z"/>
						<path id="Layer" className="s1" d="m1057.1 75.9c1.1 0.8 2.6 0.6 3.4-0.5q6.2-8.5 10.9-17.7-0.8-4-1.7-7.9c-3.6 7.9-7.9 15.6-13.2 22.7-0.8 1.1-0.5 2.6 0.6 3.4z"/>
						<path id="Layer" className="s1" d="m1028.6 106.2c9.5-6.5 18.1-14.2 25.6-22.9 0.9-1 0.8-2.6-0.3-3.4-1-0.9-2.5-0.8-3.4 0.2-8 9.3-17.3 17.4-27.6 24-0.7 0.5-1.2 1.3-1.2 2.1 0 0.8 0.5 1.6 1.2 2.1 20.8 13.3 36.8 32.2 46.7 54q0.9-3.8 1.7-7.7c-9.8-19.2-24.3-35.9-42.7-48.4z"/>
						<path id="Layer" className="s1" d="m1048.7 106.2c9.9-8.6 18.5-18.4 25.6-29.2q-0.4-3.9-1-7.7c-7.9 13.2-17.9 25-30 35-0.5 0.5-0.9 1.2-0.9 1.9 0 0.7 0.4 1.4 0.9 1.9 12 10 22.1 21.8 30 34.8q0.5-3.7 0.9-7.6c-7.1-10.7-15.6-20.5-25.5-29.1z"/>
						<path id="Layer" className="s1" d="m1075.5 96q-0.1-3.8-0.3-7.5-6.3 8.4-13.9 16c-0.4 0.4-0.7 1.1-0.7 1.7 0 0.7 0.3 1.3 0.7 1.7 5 5.1 9.7 10.4 13.9 16q0.2-3.7 0.3-7.5c-2.9-3.5-5.8-6.9-9-10.2 3.2-3.3 6.2-6.7 9-10.2z"/>
						<path id="Layer" className="s1" d="m947.6 205.4c-36.2-9.1-64.5-37.2-74-73.3-2.3-9.1-10.6-15.5-20-15.5h-11.1q0.1 2.5 0.2 4.9h10.9c7.2 0 13.5 4.9 15.3 11.8 9.9 37.9 39.6 67.3 77.5 76.8 6.4 1.7 10.9 7.5 10.9 14.2v46.3q0.7 0 1.5 0 0.1 0 0.2 0 0.1 0 0.2 0c1 0 2 0 3-0.1v-46.2c0-9-6-16.7-14.6-18.9z"/>
						<path id="Layer" className="s1" d="m944.6 217.3c-40.5-10.3-72.2-41.7-82.8-82.1-1-3.7-4.4-6.3-8.2-6.3h-10.5c0.2 1.6 0.3 3.2 0.5 4.8h10c1.6 0 3 1.2 3.5 2.8 11 42.1 44.1 74.9 86.3 85.5 0.9 0.3 1.6 1.2 1.6 2.3v1.6c0 1.3 1.1 2.4 2.5 2.4 1.3 0 2.4-1.1 2.4-2.4v-1.6c0-3.3-2.2-6.2-5.3-7z"/>
						<path id="Layer" className="s1" d="m947.5 235.2c-1.4 0-2.5 1.1-2.5 2.5v31.8q2.5 0.4 4.9 0.6v-32.4c0-1.4-1.1-2.5-2.4-2.5z"/>
						<path id="Layer" className="s1" d="m878.1 194.5c1-1 1.1-2.5 0.2-3.5-12.4-13.8-21.7-30.4-27.1-48.1-0.3-1.1-1.2-1.8-2.3-1.8h-4.4c0.2 1.7 0.4 3.3 0.6 4.9h2c5.6 17.8 15.1 34.4 27.6 48.3 0.9 1 2.4 1.1 3.4 0.2z"/>
						<path id="Layer" className="s1" d="m935.9 227.6c-17.5-5.3-33.3-14.1-47-26.2-1.1-0.9-2.6-0.8-3.5 0.2-0.9 1-0.8 2.6 0.2 3.5 13.8 12.1 29.7 21.1 47.2 26.6v34.8q2.4 0.8 4.9 1.4v-38c0-1-0.7-2-1.8-2.3z"/>
						<path id="Layer" className="s1" d="m923.8 236.4c-30.4-11.4-55.6-32.9-71.6-60.7q3.4 10.6 7.7 20.1c15.8 19.8 36.6 35.3 60.6 44.6v21.1q2.5 1.2 4.9 2.2v-25c0-1-0.6-1.9-1.6-2.3z"/>
						<path id="Layer" className="s1" d="m911.7 244.7c-13.5-5.9-25.8-13.6-36.8-22.9 4 5.7 8.4 10.9 12.9 15.6 6.5 4.2 13.3 7.9 20.5 11.2v5.9q2.4 1.6 4.9 3.1v-10.6c0-1-0.6-1.9-1.5-2.3z"/>
						<path id="Layer" className="s1" d="m873.6 80.3c9.5-36.1 37.8-64.2 74-73.3 8.6-2.2 14.6-10 14.6-18.9v-47c-1 0-2-0.1-3-0.1q-0.1 0-0.2 0-0.1 0-0.2 0-0.8 0.1-1.5 0.1v47c0 6.7-4.5 12.5-10.9 14.1-37.9 9.6-67.6 39-77.5 76.8-1.8 7-8.1 11.9-15.3 11.9h-11q-0.1 2.4-0.1 4.9h11.1c9.4 0 17.6-6.4 20-15.5z"/>
						<path id="Layer" className="s1" d="m885.9 27.6c0.5 0.5 1.1 0.7 1.7 0.7 0.6 0 1.3-0.3 1.7-0.8 15-15.8 34.1-27 55.2-32.4 3.2-0.8 5.4-3.7 5.4-7v-13c0-1.3-1.1-2.4-2.4-2.4-1.4 0-2.5 1.1-2.5 2.4v13c0 1.1-0.7 2-1.7 2.3-22 5.5-41.9 17.2-57.5 33.7-0.9 1-0.9 2.6 0.1 3.5z"/>
						<path id="Layer" className="s1" d="m861.8 77.2c3.6-14 9.8-27 18.4-38.7 0.8-1.1 0.5-2.6-0.6-3.4-1.1-0.8-2.6-0.5-3.4 0.5-8.9 12.2-15.3 25.7-19.1 40.3-0.5 1.6-1.9 2.7-3.5 2.7h-10.1c-0.1 1.6-0.3 3.3-0.4 4.9h10.5c3.9 0 7.2-2.6 8.2-6.3z"/>
						<path id="Layer" className="s1" d="m947.5-32.3c1.3 0 2.4-1.1 2.4-2.4v-23.8q-2.4 0.3-4.9 0.7v23.1c0 1.3 1.1 2.4 2.5 2.4z"/>
						<path id="Layer" className="s1" d="m851.2 69.5c12.3-40.7 44-72.4 84.7-84.7 1.1-0.3 1.8-1.3 1.8-2.4v-38.7q-2.5 0.7-4.9 1.5v35.4c-40.9 13-72.8 44.8-85.7 85.8h-2.1c-0.2 1.6-0.4 3.2-0.6 4.9h4.5c1.1 0 2-0.7 2.3-1.8z"/>
						<path id="Layer" className="s1" d="m923.8-24.1c1-0.3 1.6-1.2 1.6-2.3v-25.7q-2.4 1.1-4.9 2.2v21.9c-24.4 9.4-45.5 25.3-61.4 45.6q-4.2 9.4-7.4 19.9c16-28.2 41.4-50.1 72.1-61.6z"/>
						<path id="Layer" className="s1" d="m913.2-34.6v-11.3q-2.5 1.4-4.9 3v6.7c-7.8 3.5-15.2 7.6-22.2 12.3q-6.6 7.1-12.5 15.6c11.3-9.8 24.1-17.9 38.1-24.1 0.9-0.3 1.5-1.2 1.5-2.2z"/>
					</g>
				</g>
			</g>
			<g id="Layer">
				<g id="Clip-Path" clipPath="url(#cp5)">
					<g id="Layer">
						<path id="Layer" className="s1" d="m870.1 114c1.3 0 2.4-1.1 2.4-2.5 0-1.3-1.1-2.4-2.4-2.4h-27.8q0 1 0.1 2.1 0 1.4-0.1 2.8z"/>
						<path id="Layer" className="s1" d="m891.4 63.4c-0.7 1.2-0.3 2.7 0.9 3.4q0.6 0.3 1.2 0.3c0.9 0 1.7-0.4 2.1-1.2 13.1-21.9 34.8-37.3 59.7-42.2 11.4-2.2 19.7-12.2 19.7-23.7v-52.1q-2.4-0.5-4.9-0.8v52.9c0 9.2-6.6 17.1-15.8 18.9-26.2 5.2-49.1 21.4-62.9 44.5z"/>
						<path id="Layer" className="s1" d="m888.2 75c-1.2-0.5-2.7 0.1-3.2 1.3-4.5 11.2-6.8 23.1-6.8 35.2 0 21.6 7.1 41.9 20.6 58.8 0.5 0.6 1.2 0.9 1.9 0.9q0.8 0 1.5-0.5c1.1-0.8 1.3-2.4 0.4-3.5-12.7-15.9-19.4-35.2-19.4-55.7 0-11.5 2.1-22.7 6.4-33.3 0.5-1.3-0.1-2.7-1.4-3.2z"/>
						<path id="Layer" className="s1" d="m957 199.6c-16-2.8-30.9-10-43.2-20.7-1-0.9-2.6-0.8-3.5 0.2-0.9 1-0.8 2.6 0.2 3.5 13 11.3 28.8 18.9 45.7 21.8 8.1 1.4 13.9 8.5 13.9 16.8v54q2.5-0.3 4.9-0.8v-53.2c0-10.7-7.5-19.8-18-21.6z"/>
						<path id="Layer" className="s1" d="m968.2 27.9c-1.1 0.6-1.6 2.1-0.9 3.3 0.5 0.8 1.3 1.2 2.1 1.2q0.7 0 1.3-0.3c9.1-5.2 15.4-14.6 16.9-25.1 0.2-1.3-0.8-2.6-2.1-2.7-1.4-0.3-2.6 0.7-2.8 2-1.2 9.1-6.7 17.1-14.5 21.6z"/>
						<path id="Layer" className="s1" d="m985.5-4.1c1.3 0 2.4-1.1 2.4-2.4v-42q-2.4-0.9-4.9-1.6v43.6c0 1.3 1.1 2.4 2.5 2.4z"/>
						<path id="Layer" className="s1" d="m959.3 186.9c-3.2-0.5-6.5-1.3-9.6-2.3-1.3-0.3-2.6 0.4-3 1.7-0.4 1.3 0.3 2.6 1.6 3 3.3 1 6.7 1.8 10.2 2.4 14.2 2.4 24.5 14.6 24.5 29.1v51.7q2.5-0.8 4.9-1.6v-50.1c0-16.9-12-31.2-28.6-33.9z"/>
						<path id="Layer" className="s1" d="m927.1 179c2.6 1.8 5.3 3.4 8 4.8q0.5 0.3 1.1 0.3c0.9 0 1.7-0.5 2.2-1.3 0.6-1.2 0.1-2.7-1.1-3.3-2.5-1.3-5-2.8-7.5-4.5-21.1-14.3-33.8-38-33.8-63.5 0-27.7 15.1-53.3 39.3-66.9 6.4-3.5 12.7-6 19.4-7.6 1.3-0.3 2.1-1.6 1.8-2.9-0.3-1.3-1.6-2.2-2.9-1.8-7.1 1.6-13.9 4.3-20.6 8-25.9 14.5-41.9 41.7-41.9 71.2 0 27.1 13.5 52.3 36 67.5z"/>
						<path id="Layer" className="s1" d="m961.3 174.8c-8.8-1.5-16.9-4.7-24.6-10-17.8-12-28.4-31.9-28.4-53.3 0-23.3 12.6-44.8 33-56.2 6.6-3.7 13.2-6 20.2-7.1 22.4-3.7 38.7-23.1 38.7-46v-45.4q-2.5-1.3-4.9-2.4v47.8c0 20.5-14.6 37.8-34.6 41.1-7.6 1.3-14.7 3.8-21.8 7.7-21.9 12.3-35.5 35.5-35.5 60.5 0 23 11.4 44.5 30.5 57.4 8.4 5.6 17.1 9.1 26.6 10.7 20.2 3.4 34.8 20.7 34.8 41.2v47.1q2.4-1.1 4.9-2.4v-44.7c0-22.9-16.4-42.3-38.9-46z"/>
						<path id="Layer" className="s1" d="m921.8 91.6c-1.3-0.4-2.7 0.3-3.1 1.5-2 6-3.1 12.1-3.1 18.4 0 18.9 9.4 36.6 25.2 47.2 6.9 4.7 14 7.6 21.8 8.8 12 2 23.1 8.2 31.2 17.2 0.5 0.6 1.2 0.9 1.9 0.9 0.6 0 1.1-0.2 1.6-0.7 1-0.9 1.1-2.4 0.2-3.4-8.8-9.9-21-16.6-34.1-18.8-7.1-1.2-13.6-3.8-19.8-8-14.4-9.8-23.1-25.9-23.1-43.2 0-5.7 1-11.4 2.8-16.8 0.5-1.3-0.2-2.6-1.5-3.1z"/>
						<path id="Layer" className="s1" d="m1004.1 195.4c-1.3 0.5-1.8 2-1.3 3.2 3.1 7 4.7 14.5 4.7 22.2v40.4q2.5-1.5 4.9-3.2v-37.2c0-8.4-1.7-16.6-5.1-24.2-0.6-1.2-2-1.8-3.2-1.2z"/>
						<path id="Layer" className="s1" d="m1002.7 30.2c1.2 0.6 2.7 0.1 3.3-1.1 4.2-8.2 6.4-17.5 6.4-26.9v-37.9q-2.4-1.7-4.9-3.2v41.1c0 8.6-2 17.2-5.9 24.7-0.6 1.2-0.1 2.7 1.1 3.3z"/>
						<path id="Layer" className="s1" d="m947.3 66c5.3-2.9 10.6-4.8 16.2-5.8 14.5-2.3 27.5-10 36.5-21.6 0.8-1.1 0.7-2.7-0.4-3.5-1.1-0.8-2.6-0.6-3.5 0.4-8.2 10.7-20.1 17.7-33.4 19.9-6.2 1-12 3.1-17.8 6.3-8 4.5-14.8 10.9-19.9 18.5-0.7 1.1-0.4 2.7 0.7 3.4q0.7 0.4 1.4 0.4c0.8 0 1.6-0.4 2-1.1 4.6-7 10.9-12.8 18.2-16.9z"/>
						<path id="Layer" className="s1" d="m965.4 150.6c-5.3-0.8-10.2-2.8-15-6.1-11-7.4-17.6-19.8-17.6-33 0-14.4 7.8-27.7 20.5-34.8 4-2.3 8-3.7 12.1-4.4 34.4-5.6 59.3-35.1 59.3-70.1v-28q-2.5-2.2-4.9-4.3v32.3c0 32.6-23.2 60.1-55.1 65.3-4.8 0.8-9.3 2.4-13.8 4.9-14.2 8-23 22.9-23 39.1 0 14.9 7.4 28.7 19.7 37.1 5.4 3.6 10.9 5.9 16.9 6.9 32 5.3 55.3 32.8 55.3 65.3v31.6q2.4-2 4.9-4.3v-27.3c0-34.9-25-64.5-59.3-70.2z"/>
						<path id="Layer" className="s1" d="m1004 62.5c-1 0.9-1.2 2.4-0.3 3.5 0.9 1 2.4 1.2 3.5 0.3 18.9-15.8 29.7-39.2 29.7-64.1v-15q-2.4-2.9-4.9-5.6v20.6c0 23.4-10.2 45.4-28 60.3z"/>
						<path id="Layer" className="s1" d="m967.4 138.5c-3.6-0.6-6.8-1.9-10.1-4.1-7.7-5.2-12.3-13.8-12.3-22.9 0-10 5.5-19.2 14.3-24.1 2.7-1.6 5.4-2.5 8.1-3 9.4-1.5 18.4-4.6 26.7-9.2 1.2-0.6 1.6-2.1 0.9-3.3-0.6-1.2-2.1-1.6-3.3-1-7.8 4.3-16.2 7.2-25.1 8.7-3.3 0.5-6.5 1.7-9.7 3.5-10.4 5.8-16.8 16.7-16.8 28.4 0 10.8 5.4 20.9 14.4 26.9 3.9 2.7 7.8 4.3 12.1 5 37.9 6.3 65.4 38.8 65.4 77.4v19.9q2.5-2.7 4.9-5.6v-14.3c0-41-29.2-75.6-69.5-82.3z"/>
						<path id="Layer" className="s1" d="m969.4 126.5c-1.9-0.3-3.4-1-5.3-2.3-4.2-2.9-6.8-7.6-6.8-12.7 0-5.5 3-10.7 8-13.4 1.5-0.9 2.8-1.4 4.1-1.6 45.5-7.4 78.7-46.1 79.7-92.2q-2.3-3.8-4.8-7.4v5.3c0 44.6-31.8 82.3-75.7 89.5-1.9 0.3-3.7 1-5.7 2.1-6.5 3.6-10.5 10.4-10.5 17.7 0 6.7 3.4 13 9 16.8 2.4 1.6 4.7 2.6 7.2 3 43.8 7.3 75.7 44.9 75.7 89.5v4.6q2.5-3.6 4.8-7.4c-1.3-45.7-34.4-84-79.7-91.5z"/>
						<path id="Layer" className="s1" d="m971 103.7c-0.4 0-0.9 0.1-2.2 0.8-2.6 1.4-4.2 4.1-4.2 7 0 2.6 1.4 5.1 3.7 6.6 1.1 0.8 1.6 1 2.3 1.1 24.8 4.1 47.1 17 62.9 36.4 0.8 1 2.4 1.2 3.4 0.3 1.1-0.8 1.2-2.4 0.4-3.4-16.6-20.3-40-33.8-65.8-38.1q-0.1-0.1-0.5-0.3c-0.9-0.6-1.5-1.6-1.5-2.6 0-1.1 0.7-2.2 1.7-2.7 0.3-0.2 0.4-0.2 0.3-0.2 44.8-7.4 79-41.6 87.7-84.8q-1.8-4-3.7-7.9c-5.8 44.5-39.7 80.5-84.5 87.8z"/>
						<path id="Layer" className="s1" d="m1043 164.7c-1.1 0.7-1.5 2.2-0.8 3.4 6.9 11.7 11.4 24.8 13.3 38.3q1.9-3.8 3.6-7.7c-2.4-11.7-6.7-22.9-12.7-33.1-0.7-1.2-2.2-1.5-3.4-0.9z"/>
						<path id="Layer" className="s1" d="m996.6 109.2c-1.3 0.5-1.9 2-1.4 3.2 0.5 1.3 1.9 1.9 3.2 1.4 32.4-12.9 56.7-38.7 68.1-70.4q-1.2-3.9-2.6-7.8c-9.9 33.2-34.1 60.5-67.3 73.6z"/>
						<path id="Layer" className="s1" d="m1016.4 118.2c-1.2-0.7-2.7-0.3-3.4 0.8-0.7 1.2-0.3 2.7 0.8 3.4 24.5 15 41.9 37.9 50 64.6q1.3-3.8 2.6-7.8c-9.2-25-26.4-46.6-50-61z"/>
						<path id="Layer" className="s1" d="m1057.1 81.3c1.1 0.8 2.6 0.5 3.4-0.6q6.2-8.5 10.9-17.7-0.8-4-1.7-7.9c-3.6 8-7.9 15.6-13.2 22.7-0.8 1.1-0.5 2.6 0.6 3.5z"/>
						<path id="Layer" className="s1" d="m1028.6 111.5c9.5-6.5 18.1-14.1 25.6-22.9 0.9-1 0.8-2.5-0.3-3.4-1-0.9-2.5-0.8-3.4 0.2-8 9.3-17.3 17.4-27.6 24.1-0.7 0.4-1.2 1.2-1.2 2 0 0.9 0.5 1.6 1.2 2.1 20.8 13.3 36.8 32.2 46.7 54q0.9-3.8 1.7-7.7c-9.8-19.2-24.3-35.9-42.7-48.4z"/>
						<path id="Layer" className="s1" d="m1048.7 111.5c9.9-8.6 18.5-18.4 25.6-29.2q-0.4-3.9-1-7.7c-7.9 13.2-17.9 25-30 35-0.5 0.5-0.9 1.2-0.9 1.9 0 0.7 0.4 1.4 0.9 1.9 12 10 22.1 21.8 30 34.9q0.5-3.8 0.9-7.7c-7.1-10.7-15.6-20.5-25.5-29.1z"/>
						<path id="Layer" className="s1" d="m1075.5 101.4q-0.1-3.9-0.3-7.6-6.3 8.4-13.9 16c-0.4 0.5-0.7 1.1-0.7 1.7 0 0.7 0.3 1.3 0.7 1.8 5 5 9.7 10.3 13.9 15.9q0.2-3.7 0.3-7.5c-2.9-3.5-5.8-6.9-9-10.2 3.2-3.2 6.2-6.6 9-10.1z"/>
						<path id="Layer" className="s1" d="m947.6 210.7c-36.2-9.1-64.5-37.2-74-73.3-2.3-9.1-10.6-15.5-20-15.5h-11.1q0.1 2.5 0.2 4.9h10.9c7.2 0 13.5 4.9 15.3 11.9 9.9 37.8 39.6 67.2 77.5 76.8 6.4 1.6 10.9 7.4 10.9 14.1v46.3q0.7 0 1.5 0 0.1 0 0.2 0 0.1 0 0.2 0c1 0 2 0 3 0v-46.3c0-9-6-16.7-14.6-18.9z"/>
						<path id="Layer" className="s1" d="m944.6 222.6c-40.5-10.2-72.2-41.7-82.8-82.1-1-3.7-4.4-6.3-8.2-6.3h-10.5c0.2 1.6 0.3 3.2 0.5 4.9h10c1.6 0 3 1.1 3.5 2.7 11 42.1 44.1 74.9 86.3 85.5 0.9 0.3 1.6 1.2 1.6 2.3v1.6c0 1.3 1.1 2.4 2.5 2.4 1.3 0 2.4-1.1 2.4-2.4v-1.6c0-3.3-2.2-6.2-5.3-7z"/>
						<path id="Layer" className="s1" d="m947.5 240.6c-1.4 0-2.5 1.1-2.5 2.4v31.8q2.5 0.4 4.9 0.7v-32.5c0-1.3-1.1-2.4-2.4-2.4z"/>
						<path id="Layer" className="s1" d="m878.1 199.8c1-0.9 1.1-2.5 0.2-3.5-12.4-13.8-21.7-30.4-27.1-48.1-0.3-1.1-1.2-1.8-2.3-1.8h-4.4c0.2 1.7 0.4 3.3 0.6 4.9h2c5.6 17.8 15.1 34.4 27.6 48.3 0.9 1 2.4 1.1 3.4 0.2z"/>
						<path id="Layer" className="s1" d="m935.9 232.9c-17.5-5.3-33.3-14.1-47-26.2-1.1-0.9-2.6-0.8-3.5 0.2-0.9 1.1-0.8 2.6 0.2 3.5 13.8 12.2 29.7 21.1 47.2 26.7v34.7q2.4 0.8 4.9 1.4v-38c0-1-0.7-2-1.8-2.3z"/>
						<path id="Layer" className="s1" d="m923.8 241.8c-30.4-11.4-55.6-33-71.6-60.8q3.4 10.6 7.7 20.1c15.8 19.8 36.6 35.3 60.6 44.6v21.1q2.5 1.2 4.9 2.3v-25.1c0-1-0.6-1.9-1.6-2.3z"/>
						<path id="Layer" className="s1" d="m911.7 250c-13.5-5.9-25.8-13.6-36.8-22.9 4 5.7 8.4 10.9 12.9 15.6 6.5 4.2 13.3 8 20.5 11.2v6q2.4 1.6 4.9 3v-10.6c0-1-0.6-1.9-1.5-2.3z"/>
						<path id="Layer" className="s1" d="m873.6 85.6c9.5-36.1 37.8-64.2 74-73.3 8.6-2.2 14.6-9.9 14.6-18.9v-47c-1 0-2-0.1-3 0q-0.1 0-0.2 0-0.1 0-0.2 0-0.8 0-1.5 0v47c0 6.7-4.5 12.5-10.9 14.1-37.9 9.6-67.6 39-77.5 76.8-1.8 7-8.1 11.9-15.3 11.9h-11q-0.1 2.4-0.1 4.9h11.1c9.4 0 17.6-6.4 20-15.5z"/>
						<path id="Layer" className="s1" d="m885.9 32.9c0.5 0.5 1.1 0.7 1.7 0.7 0.6 0 1.3-0.3 1.7-0.8 15-15.8 34.1-27 55.2-32.4 3.2-0.8 5.4-3.6 5.4-7v-13c0-1.3-1.1-2.4-2.4-2.4-1.4 0-2.5 1.1-2.5 2.4v13c0 1.1-0.7 2-1.7 2.3-22 5.6-41.9 17.2-57.5 33.8-0.9 0.9-0.9 2.5 0.1 3.4z"/>
						<path id="Layer" className="s1" d="m861.8 82.5c3.6-14 9.8-27 18.4-38.6 0.8-1.1 0.5-2.7-0.6-3.5-1.1-0.8-2.6-0.5-3.4 0.6-8.9 12.1-15.3 25.7-19.1 40.2-0.5 1.6-1.9 2.7-3.5 2.7h-10.1c-0.1 1.7-0.3 3.3-0.4 4.9h10.5c3.9 0 7.2-2.6 8.2-6.3z"/>
						<path id="Layer" className="s1" d="m947.5-26.9c1.3 0 2.4-1.1 2.4-2.5v-23.8q-2.4 0.3-4.9 0.7v23.1c0 1.4 1.1 2.5 2.5 2.5z"/>
						<path id="Layer" className="s1" d="m851.2 74.8c12.3-40.7 44-72.4 84.7-84.7 1.1-0.3 1.8-1.3 1.8-2.4v-38.6q-2.5 0.6-4.9 1.4v35.4c-40.9 13-72.8 44.9-85.7 85.8h-2.1c-0.2 1.6-0.4 3.2-0.6 4.9h4.5c1.1 0 2-0.7 2.3-1.8z"/>
						<path id="Layer" className="s1" d="m923.8-18.7c1-0.4 1.6-1.3 1.6-2.3v-25.7q-2.4 1-4.9 2.2v21.8c-24.4 9.5-45.5 25.4-61.4 45.6q-4.2 9.4-7.4 19.9c16-28.2 41.4-50.1 72.1-61.5z"/>
						<path id="Layer" className="s1" d="m913.2-29.3v-11.3q-2.5 1.4-4.9 3v6.7c-7.8 3.5-15.2 7.7-22.2 12.3q-6.6 7.1-12.5 15.6c11.3-9.7 24.1-17.9 38.1-24 0.9-0.4 1.5-1.3 1.5-2.3z"/>
					</g>
				</g>
			</g>
			<g id="Layer">
				<g id="Clip-Path" clipPath="url(#cp6)">
					<g id="Layer">
						<path id="Layer" className="s1" d="m870.1 119.3c1.3 0 2.4-1.1 2.4-2.5 0-1.3-1.1-2.4-2.4-2.4h-27.8q0 1 0.1 2.1 0 1.4-0.1 2.8z"/>
						<path id="Layer" className="s1" d="m891.4 68.7c-0.7 1.2-0.3 2.7 0.9 3.4q0.6 0.3 1.2 0.3c0.9 0 1.7-0.4 2.1-1.2 13.1-21.9 34.8-37.3 59.7-42.1 11.4-2.3 19.7-12.3 19.7-23.8v-52.1q-2.4-0.5-4.9-0.8v52.9c0 9.2-6.6 17.1-15.8 18.9-26.2 5.2-49.1 21.4-62.9 44.5z"/>
						<path id="Layer" className="s1" d="m888.2 80.3c-1.2-0.5-2.7 0.1-3.2 1.4-4.5 11.1-6.8 23-6.8 35.1 0 21.6 7.1 42 20.6 58.8 0.5 0.6 1.2 0.9 1.9 0.9q0.8 0 1.5-0.5c1.1-0.8 1.3-2.4 0.4-3.4-12.7-16-19.4-35.3-19.4-55.8 0-11.5 2.1-22.7 6.4-33.3 0.5-1.3-0.1-2.7-1.4-3.2z"/>
						<path id="Layer" className="s1" d="m957 204.9c-16-2.8-30.9-10-43.2-20.7-1-0.9-2.6-0.8-3.5 0.2-0.9 1.1-0.8 2.6 0.2 3.5 13 11.3 28.8 18.9 45.7 21.8 8.1 1.5 13.9 8.5 13.9 16.8v54q2.5-0.3 4.9-0.8v-53.2c0-10.7-7.5-19.8-18-21.6z"/>
						<path id="Layer" className="s1" d="m968.2 33.2c-1.1 0.7-1.6 2.2-0.9 3.3 0.5 0.8 1.3 1.3 2.1 1.3q0.7 0 1.3-0.4c9.1-5.2 15.4-14.5 16.9-25 0.2-1.4-0.8-2.6-2.1-2.8-1.4-0.2-2.6 0.7-2.8 2.1-1.2 9-6.7 17-14.5 21.5z"/>
						<path id="Layer" className="s1" d="m985.5 1.3c1.3-0.1 2.4-1.2 2.4-2.5v-42q-2.4-0.9-4.9-1.6v43.6c0 1.3 1.1 2.4 2.5 2.4z"/>
						<path id="Layer" className="s1" d="m959.3 192.2c-3.2-0.5-6.5-1.3-9.6-2.2-1.3-0.4-2.6 0.3-3 1.6-0.4 1.3 0.3 2.7 1.6 3 3.3 1.1 6.7 1.9 10.2 2.4 14.2 2.4 24.5 14.6 24.5 29.1v51.7q2.5-0.7 4.9-1.6v-50.1c0-16.9-12-31.1-28.6-33.9z"/>
						<path id="Layer" className="s1" d="m927.1 184.4c2.6 1.7 5.3 3.4 8 4.8q0.5 0.2 1.1 0.2c0.9 0 1.7-0.4 2.2-1.3 0.6-1.2 0.1-2.6-1.1-3.3-2.5-1.3-5-2.8-7.5-4.5-21.1-14.3-33.8-38-33.8-63.5 0-27.7 15.1-53.3 39.3-66.9 6.4-3.5 12.7-6 19.4-7.6 1.3-0.3 2.1-1.6 1.8-2.9-0.3-1.3-1.6-2.1-2.9-1.8-7.1 1.6-13.9 4.3-20.6 8.1-25.9 14.4-41.9 41.7-41.9 71.1 0 27.1 13.5 52.3 36 67.6z"/>
						<path id="Layer" className="s1" d="m961.3 180.1c-8.8-1.4-16.9-4.7-24.6-10-17.8-12-28.4-31.9-28.4-53.3 0-23.2 12.6-44.8 33-56.2 6.6-3.6 13.2-6 20.2-7.1 22.4-3.7 38.7-23 38.7-46v-45.4q-2.5-1.3-4.9-2.4v47.8c0 20.5-14.6 37.9-34.6 41.1-7.6 1.3-14.7 3.8-21.8 7.7-21.9 12.3-35.5 35.5-35.5 60.5 0 23 11.4 44.5 30.5 57.4 8.4 5.7 17.1 9.2 26.6 10.7 20.2 3.4 34.8 20.7 34.8 41.2v47.1q2.4-1.1 4.9-2.4v-44.7c0-22.9-16.4-42.2-38.9-46z"/>
						<path id="Layer" className="s1" d="m921.8 96.9c-1.3-0.4-2.7 0.3-3.1 1.5-2 6-3.1 12.2-3.1 18.4 0 18.9 9.4 36.6 25.2 47.3 6.9 4.6 14 7.5 21.8 8.8 12 2 23.1 8.1 31.2 17.2 0.5 0.5 1.2 0.8 1.9 0.8 0.6 0 1.1-0.2 1.6-0.6 1-0.9 1.1-2.5 0.2-3.5-8.8-9.9-21-16.6-34.1-18.8-7.1-1.1-13.6-3.8-19.8-8-14.4-9.7-23.1-25.9-23.1-43.2 0-5.7 1-11.4 2.8-16.8 0.5-1.2-0.2-2.6-1.5-3.1z"/>
						<path id="Layer" className="s1" d="m1004.1 200.7c-1.3 0.6-1.8 2-1.3 3.3 3.1 6.9 4.7 14.4 4.7 22.1v40.5q2.5-1.6 4.9-3.3v-37.2c0-8.4-1.7-16.5-5.1-24.1-0.6-1.3-2-1.8-3.2-1.3z"/>
						<path id="Layer" className="s1" d="m1002.7 35.5c1.2 0.6 2.7 0.2 3.3-1.1 4.2-8.2 6.4-17.5 6.4-26.9v-37.9q-2.4-1.7-4.9-3.2v41.1c0 8.6-2 17.2-5.9 24.7-0.6 1.2-0.1 2.7 1.1 3.3z"/>
						<path id="Layer" className="s1" d="m947.3 71.3c5.3-2.9 10.6-4.8 16.2-5.7 14.5-2.4 27.5-10.1 36.5-21.7 0.8-1.1 0.7-2.6-0.4-3.5-1.1-0.8-2.6-0.6-3.5 0.5-8.2 10.6-20.1 17.6-33.4 19.8-6.2 1-12 3.1-17.8 6.3-8 4.5-14.8 10.9-19.9 18.5-0.7 1.2-0.4 2.7 0.7 3.4q0.7 0.4 1.4 0.4c0.8 0 1.6-0.4 2-1.1 4.6-6.9 10.9-12.8 18.2-16.9z"/>
						<path id="Layer" className="s1" d="m965.4 156c-5.3-0.9-10.2-2.9-15-6.1-11-7.5-17.6-19.8-17.6-33.1 0-14.4 7.8-27.7 20.5-34.8 4-2.2 8-3.7 12.1-4.3 34.4-5.6 59.3-35.2 59.3-70.2v-28q-2.5-2.2-4.9-4.3v32.3c0 32.6-23.2 60.1-55.1 65.3-4.8 0.8-9.3 2.4-13.8 5-14.2 7.9-23 22.9-23 39 0 14.9 7.4 28.8 19.7 37.1 5.4 3.7 10.9 5.9 16.9 6.9 32 5.3 55.3 32.8 55.3 65.3v31.6q2.4-2 4.9-4.3v-27.3c0-34.9-25-64.4-59.3-70.1z"/>
						<path id="Layer" className="s1" d="m1004 67.9c-1 0.8-1.2 2.4-0.3 3.4 0.9 1.1 2.4 1.2 3.5 0.3 18.9-15.8 29.7-39.2 29.7-64.1v-15q-2.4-2.9-4.9-5.6v20.6c0 23.5-10.2 45.5-28 60.4z"/>
						<path id="Layer" className="s1" d="m967.4 143.9c-3.6-0.6-6.8-1.9-10.1-4.2-7.7-5.2-12.3-13.7-12.3-22.9 0-9.9 5.5-19.2 14.3-24.1 2.7-1.5 5.4-2.5 8.1-2.9 9.4-1.6 18.4-4.7 26.7-9.2 1.2-0.7 1.6-2.2 0.9-3.4-0.6-1.2-2.1-1.6-3.3-0.9-7.8 4.2-16.2 7.2-25.1 8.6-3.3 0.6-6.5 1.7-9.7 3.5-10.4 5.8-16.8 16.7-16.8 28.4 0 10.8 5.4 20.9 14.4 27 3.9 2.6 7.8 4.2 12.1 4.9 37.9 6.3 65.4 38.9 65.4 77.4v19.9q2.5-2.7 4.9-5.6v-14.3c0-40.9-29.2-75.5-69.5-82.2z"/>
						<path id="Layer" className="s1" d="m969.4 131.8c-1.9-0.3-3.4-1-5.3-2.2-4.2-2.9-6.8-7.7-6.8-12.8 0-5.5 3-10.7 8-13.4 1.5-0.9 2.8-1.4 4.1-1.6 45.5-7.4 78.7-46 79.7-92.2q-2.3-3.8-4.8-7.4v5.3c0 44.7-31.8 82.3-75.7 89.5-1.9 0.3-3.7 1-5.7 2.1-6.5 3.6-10.5 10.4-10.5 17.7 0 6.7 3.4 13 9 16.8 2.4 1.7 4.7 2.6 7.2 3 43.8 7.3 75.7 44.9 75.7 89.5v4.6q2.5-3.6 4.8-7.3c-1.3-45.8-34.4-84.1-79.7-91.6z"/>
						<path id="Layer" className="s1" d="m971 109.1c-0.4 0-0.9 0-2.2 0.7-2.6 1.5-4.2 4.2-4.2 7 0 2.7 1.4 5.1 3.7 6.7 1.1 0.7 1.6 0.9 2.3 1 24.8 4.1 47.1 17.1 62.9 36.4 0.8 1.1 2.4 1.2 3.4 0.4 1.1-0.9 1.2-2.4 0.4-3.5-16.6-20.3-40-33.8-65.8-38.1q-0.1 0-0.5-0.3c-0.9-0.6-1.5-1.6-1.5-2.6 0-1.1 0.7-2.1 1.7-2.7 0.3-0.1 0.4-0.2 0.3-0.2 44.8-7.4 79-41.6 87.7-84.8q-1.8-4-3.7-7.8c-5.8 44.5-39.7 80.4-84.5 87.8z"/>
						<path id="Layer" className="s1" d="m1043 170.1c-1.1 0.7-1.5 2.2-0.8 3.3 6.9 11.8 11.4 24.8 13.3 38.3q1.9-3.8 3.6-7.7c-2.4-11.6-6.7-22.8-12.7-33.1-0.7-1.1-2.2-1.5-3.4-0.8z"/>
						<path id="Layer" className="s1" d="m996.6 114.6c-1.3 0.5-1.9 1.9-1.4 3.1 0.5 1.3 1.9 1.9 3.2 1.4 32.4-12.9 56.7-38.7 68.1-70.4q-1.2-3.9-2.6-7.7c-9.9 33.1-34.1 60.4-67.3 73.6z"/>
						<path id="Layer" className="s1" d="m1016.4 123.6c-1.2-0.7-2.7-0.4-3.4 0.8-0.7 1.1-0.3 2.6 0.8 3.3 24.5 15 41.9 38 50 64.6q1.3-3.8 2.6-7.8c-9.2-25-26.4-46.5-50-60.9z"/>
						<path id="Layer" className="s1" d="m1057.1 86.6c1.1 0.8 2.6 0.5 3.4-0.6q6.2-8.4 10.9-17.7-0.8-4-1.7-7.9c-3.6 8-7.9 15.6-13.2 22.8-0.8 1-0.5 2.6 0.6 3.4z"/>
						<path id="Layer" className="s1" d="m1028.6 116.9c9.5-6.5 18.1-14.2 25.6-22.9 0.9-1.1 0.8-2.6-0.3-3.5-1-0.9-2.5-0.8-3.4 0.3-8 9.3-17.3 17.4-27.6 24-0.7 0.4-1.2 1.2-1.2 2.1 0 0.8 0.5 1.6 1.2 2 20.8 13.4 36.8 32.2 46.7 54q0.9-3.8 1.7-7.7c-9.8-19.2-24.3-35.8-42.7-48.3z"/>
						<path id="Layer" className="s1" d="m1048.7 116.9c9.9-8.6 18.5-18.5 25.6-29.2q-0.4-3.9-1-7.7c-7.9 13.1-17.9 25-30 35-0.5 0.4-0.9 1.1-0.9 1.9 0 0.7 0.4 1.4 0.9 1.8 12 10 22.1 21.8 30 34.9q0.5-3.8 0.9-7.7c-7.1-10.7-15.6-20.5-25.5-29z"/>
						<path id="Layer" className="s1" d="m1075.5 106.7q-0.1-3.8-0.3-7.6-6.3 8.4-13.9 16c-0.4 0.5-0.7 1.1-0.7 1.8 0 0.6 0.3 1.2 0.7 1.7 5 5 9.7 10.3 13.9 15.9q0.2-3.7 0.3-7.5c-2.9-3.5-5.8-6.9-9-10.1q4.8-5 9-10.2z"/>
						<path id="Layer" className="s1" d="m947.6 216c-36.2-9.1-64.5-37.2-74-73.3-2.3-9.1-10.6-15.5-20-15.5h-11.1q0.1 2.5 0.2 4.9h10.9c7.2 0 13.5 4.9 15.3 11.9 9.9 37.8 39.6 67.2 77.5 76.8 6.4 1.6 10.9 7.4 10.9 14.1v46.3q0.7 0.1 1.5 0.1 0.1 0 0.2 0 0.1 0 0.2 0c1 0 2-0.1 3-0.1v-46.3c0-8.9-6-16.7-14.6-18.9z"/>
						<path id="Layer" className="s1" d="m944.6 227.9c-40.5-10.2-72.2-41.7-82.8-82.1-1-3.7-4.4-6.3-8.2-6.3h-10.5c0.2 1.6 0.3 3.3 0.5 4.9h10c1.6 0 3 1.1 3.5 2.7 11 42.1 44.1 74.9 86.3 85.6 0.9 0.2 1.6 1.1 1.6 2.2v1.6c0 1.3 1.1 2.4 2.5 2.4 1.3 0 2.4-1.1 2.4-2.4v-1.6c0-3.3-2.2-6.2-5.3-7z"/>
						<path id="Layer" className="s1" d="m947.5 245.9c-1.4 0-2.5 1.1-2.5 2.4v31.8q2.5 0.4 4.9 0.7v-32.5c0-1.3-1.1-2.4-2.4-2.4z"/>
						<path id="Layer" className="s1" d="m878.1 205.1c1-0.9 1.1-2.5 0.2-3.5-12.4-13.7-21.7-30.4-27.1-48.1-0.3-1-1.2-1.7-2.3-1.7h-4.4c0.2 1.6 0.4 3.2 0.6 4.8h2c5.6 17.8 15.1 34.5 27.6 48.3 0.9 1 2.4 1.1 3.4 0.2z"/>
						<path id="Layer" className="s1" d="m935.9 238.2c-17.5-5.2-33.3-14.1-47-26.2-1.1-0.9-2.6-0.8-3.5 0.3-0.9 1-0.8 2.5 0.2 3.4 13.8 12.2 29.7 21.1 47.2 26.7v34.7q2.4 0.8 4.9 1.4v-37.9c0-1.1-0.7-2.1-1.8-2.4z"/>
						<path id="Layer" className="s1" d="m923.8 247.1c-30.4-11.4-55.6-33-71.6-60.7q3.4 10.6 7.7 20.1c15.8 19.7 36.6 35.2 60.6 44.6v21.1q2.5 1.1 4.9 2.2v-25c0-1-0.6-2-1.6-2.3z"/>
						<path id="Layer" className="s1" d="m911.7 255.4c-13.5-5.9-25.8-13.7-36.8-23 4 5.7 8.4 10.9 12.9 15.7 6.5 4.1 13.3 7.9 20.5 11.1v6q2.4 1.6 4.9 3v-10.6c0-1-0.6-1.8-1.5-2.2z"/>
						<path id="Layer" className="s1" d="m873.6 90.9c9.5-36.1 37.8-64.2 74-73.3 8.6-2.2 14.6-9.9 14.6-18.9v-46.9c-1-0.1-2-0.1-3-0.1q-0.1 0-0.2 0-0.1 0-0.2 0-0.8 0-1.5 0v47c0 6.7-4.5 12.5-10.9 14.2-37.9 9.5-67.6 39-77.5 76.8-1.8 7-8.1 11.8-15.3 11.8h-11q-0.1 2.5-0.1 4.9h11.1c9.4 0 17.6-6.4 20-15.5z"/>
						<path id="Layer" className="s1" d="m885.9 38.2c0.5 0.5 1.1 0.7 1.7 0.7 0.6 0 1.3-0.2 1.7-0.7 15-15.9 34.1-27.1 55.2-32.4 3.2-0.8 5.4-3.7 5.4-7.1v-13c0-1.3-1.1-2.4-2.4-2.4-1.4 0-2.5 1.1-2.5 2.4v13c0 1.1-0.7 2.1-1.7 2.3-22 5.6-41.9 17.3-57.5 33.8-0.9 1-0.9 2.5 0.1 3.4z"/>
						<path id="Layer" className="s1" d="m861.8 87.8c3.6-14 9.8-27 18.4-38.6 0.8-1.1 0.5-2.7-0.6-3.4-1.1-0.9-2.6-0.6-3.4 0.5-8.9 12.1-15.3 25.7-19.1 40.3-0.5 1.5-1.9 2.7-3.5 2.7h-10.1c-0.1 1.6-0.3 3.2-0.4 4.9h10.5c3.9 0 7.2-2.6 8.2-6.4z"/>
						<path id="Layer" className="s1" d="m947.5-21.6c1.3 0 2.4-1.1 2.4-2.5v-23.7q-2.4 0.2-4.9 0.6v23.1c0 1.4 1.1 2.5 2.5 2.5z"/>
						<path id="Layer" className="s1" d="m851.2 80.2c12.3-40.8 44-72.5 84.7-84.8 1.1-0.3 1.8-1.2 1.8-2.3v-38.7q-2.5 0.6-4.9 1.4v35.5c-40.9 12.9-72.8 44.8-85.7 85.7h-2.1c-0.2 1.6-0.4 3.3-0.6 4.9h4.5c1.1 0 2-0.7 2.3-1.7z"/>
						<path id="Layer" className="s1" d="m923.8-13.4c1-0.4 1.6-1.3 1.6-2.3v-25.7q-2.4 1-4.9 2.2v21.8c-24.4 9.5-45.5 25.4-61.4 45.6q-4.2 9.5-7.4 19.9c16-28.1 41.4-50 72.1-61.5z"/>
						<path id="Layer" className="s1" d="m913.2-24v-11.3q-2.5 1.5-4.9 3.1v6.6c-7.8 3.5-15.2 7.7-22.2 12.3q-6.6 7.1-12.5 15.6c11.3-9.7 24.1-17.9 38.1-24 0.9-0.4 1.5-1.3 1.5-2.3z"/>
					</g>
				</g>
			</g>
			<g id="Layer">
				<path id="Layer" fillRule="evenodd" className="s1" d="m1049.7 4.2v52.8c0 2.4-1.9 4.3-4.2 4.3h-173.6c-2.4 0-4.3-1.9-4.3-4.3v-52.8c0-2.3 1.9-4.2 4.3-4.2h173.6c2.3 0 4.2 1.9 4.2 4.2zm-177.8 0v52.8h173.6v-52.8z"/>
			</g>
			<g id="Layer">
				<path id="Layer" fillRule="evenodd" className="s1" d="m1024.1 90.5v51c0 2.4-1.9 4.3-4.3 4.3h-147.9c-2.4 0-4.3-1.9-4.3-4.3v-51c0-2.4 1.9-4.3 4.3-4.3h147.9c2.4 0 4.3 1.9 4.3 4.3zm-152.2 0v51h147.9v-51z"/>
			</g>
			<g id="Layer">
				<path id="Layer" fillRule="evenodd" className="s1" d="m1049.7 175v52.8c0 2.3-1.9 4.3-4.2 4.3h-173.6c-2.4 0-4.3-2-4.3-4.3v-52.8c0-2.3 1.9-4.3 4.3-4.3h173.6c2.3 0 4.2 2 4.2 4.3zm-177.8 0v52.8h173.6v-52.8z"/>
			</g>
		</g>
		<path id="Layer" style={{fill:fill}} d="m111.6 128c1.6-2 4.6-2.2 6.4-0.2q8.9 9.6 18.7 19.9 11.6 12.2 23.1 24.6 11.4 12.4 22.6 24.5 11.2 12 21.3 22.4 2.1 2.5 1.5 5.6-0.6 3-4 3h-63.3q-3.4 0-6.9-1.5-3.5-1.5-5.3-4l-44.9-50.2c-1.4-1.5-1.4-3.8-0.1-5.5z"/>
	
		<g style={{width:"100%",fontSize:"61.2px", fill:fill}}><text x="-4" y="310">Designed in California, Made in Bharat</text></g>
	</g>
</svg>
  );
}
